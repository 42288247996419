import { DayInfo } from '../../../services/companyTimesheetUserService'

export function getDateTimeFromDates(dayInfos: DayInfo[]): string {
    const sortedDayInfos = dayInfos.sort(
        (a, b) => a.date.getTime() - b.date.getTime()
    )
    const firstDate = sortedDayInfos[0].date
    const lastDate = sortedDayInfos[sortedDayInfos.length - 1].date
    return `${firstDate.toLocaleDateString()} - ${lastDate.toLocaleDateString()}`
}
