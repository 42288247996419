import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import FormProposal from '../../components/formProposal/FormProposal'
import {
    CompanyProposalEditables,
    ProposalStatus,
    RateType,
    useCreateCompanyProposal,
} from '../../services/companyProposalService'

interface CreateProposalProps {
    title?: string
    url?: string
    onSubmitted?: () => void
}

function CreateProposal({ title = '', url, onSubmitted }: CreateProposalProps) {
    const proposalMutation = useCreateCompanyProposal()
    const getDefaults = (): CompanyProposalEditables => {
        const data = {
            title: title,
            clientId: '',
            rateType: RateType.HOURLY,
        } as CompanyProposalEditables
        if (url) data.link = url
        return data
    }
    const [form, setForm] = useState<CompanyProposalEditables>(getDefaults())

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === undefined) return
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        } as CompanyProposalEditables)
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        await proposalMutation.mutateAsync(form)
        onSubmitted && onSubmitted()
    }

    return (
        <Box
            sx={{
                '> *': {
                    mb: 4,
                },
            }}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <Typography variant="h3" component="h2" sx={{ mb: 4 }}>
                Skapa anbud
            </Typography>
            <FormProposal
                values={form}
                onChange={handleChange}
                onClientChange={(clientId) => {
                    setForm({ ...form, clientId })
                }}
            />
            <Button type="submit" variant="contained" size="large" fullWidth>
                Spara
            </Button>
        </Box>
    )
}

export default CreateProposal
