import { DayInfo } from '../../../services/companyTimesheetUserService'

export function getGroupedWeeks(
    dayInfos: DayInfo[]
): Record<number, DayInfo[]> {
    const grouped: Record<number, DayInfo[]> = {}

    dayInfos.forEach((dayInfo: DayInfo) => {
        const weekNumber = dayInfo.weekNumber
        if (!grouped[weekNumber]) {
            grouped[weekNumber] = []
        }
        grouped[weekNumber].push(dayInfo)
    })

    return grouped
}
