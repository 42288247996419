// @ts-nocheck
import './App.css'
import {
    Box,
    CircularProgress,
    CssBaseline,
    ThemeProvider,
} from '@mui/material'
import Proposals from './pages/proposals/Proposals'
import Projects from './pages/projects/Projects'
import Leads from './pages/leads/Leads'
import { theme } from './libs/material-ui'
import Timesheets from './pages/timesheets/Timesheets'
import useAuth from './hooks/UseAuth'
import Signup from './pages/signup/Signup'
import Signin from './pages/signin/Signin'
import ResetPassword from './pages/resetPassword/ResetPassword'
import Invoices from './pages/invoices/Invoices'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import LayoutAuth from './components/layoutAuth/LayoutAuth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import CreateProposalExtension from './pages/proposals/CreateProposalExtension'
import AuthProvider from './context/AuthContext'
import SnackbarProvider from './context/SnackbarContext'
import BetaSignup from './pages/signup/SignupBeta'

const publicRouter = createBrowserRouter([
    {
        path: '/beta',
        element: <BetaSignup />,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
    },
    {
        path: '/login',
        element: <Signin />,
    },
    {
        path: '/*',
        element: <Signin />,
    },
])

const privateRouter = createBrowserRouter([
    {
        path: '/',
        element: <LayoutAuth />,
        children: [
            {
                path: '/leads',
                element: <Leads />,
            },
            {
                path: '/proposals',
                element: <Proposals />,
            },
            {
                path: '/projects',
                element: <Projects />,
            },
            {
                path: '/timesheet',
                element: <Timesheets />,
            },
            {
                path: '/invoices',
                element: <Invoices />,
            },
            {
                path: '/*',
                element: <Leads />,
            },
            {
                path: '/',
                element: <Leads />,
            },
        ],
    },
])

const queryClient = new QueryClient()

export default function AppProvider() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <AuthProvider>
                        <CssBaseline />
                        <App />
                    </AuthProvider>
                </SnackbarProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

function App() {
    const { isLoading, user } = useAuth()

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress size="large" />
            </Box>
        )
    }

    return (
        <Box>
            {window.location.pathname === '/extension' ? (
                <CreateProposalExtension />
            ) : (
                <RouterProvider router={!user ? publicRouter : privateRouter} />
            )}
        </Box>
    )
}
