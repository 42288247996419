import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode } from 'react'

interface ContentLayoutProps {
    title: string
    actionBar?: ReactNode
    children: ReactNode | ReactNode[]
}

export default function ContentLayout({
    title,
    actionBar,
    children,
}: ContentLayoutProps) {
    const isBelowMdScreen = useMediaQuery('(max-width:850px)')
    const theme = useTheme()
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pb: isBelowMdScreen ? 2 : 4,
                    alignItems: 'center', // Align items vertically in the box
                }}
            >
                <div // Wrap the Typography with a div for text overflow handling
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flex: 1, // Allow the Typography to take available space
                    }}
                >
                    <Typography
                        variant="h3"
                        component="h1"
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '20px', // Adjust font-size for small screens
                            },
                        }}
                    >
                        {title}
                    </Typography>
                </div>
                {actionBar}
            </Box>
            <Paper>{children}</Paper>
        </Box>
    )
}
