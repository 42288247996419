import {
    Box,
    Button,
    Chip,
    Collapse,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import ContentLayout from '../../components/contentLayout/ContentLayout'
import { getCurrentYearMonth } from '../timesheets/utils/getCurrentYearMonth'
import { useCompanyTimesheets } from '../../services/companyTimesheetService'
import {
    CompanyProjectDoc,
    useCompanyProjects,
} from '../../services/companyProjectService'
import { DayInfo } from '../../services/companyTimesheetUserService'
import { Check } from '@mui/icons-material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import {
    ReadCompentClientDoc,
    useCompanyClients,
} from '../../services/companyClientService'

interface ProjectTimesheet extends CompanyProjectDoc {
    timesheet: { [day: number]: DayInfo }
}

interface ClientInvoices extends ReadCompentClientDoc {
    projects: ProjectTimesheet[]
}

export default function Invoices() {
    const [monthYear, setMonthYear] = useState<string>(getCurrentYearMonth())
    const timesheets = useCompanyTimesheets(monthYear)
    const projects = useCompanyProjects()
    const clients = useCompanyClients()
    const [data, setData] = useState<{ [key: string]: ClientInvoices }>({})
    const isBelowMdScreen = useMediaQuery('(max-width:850px)')

    useEffect(() => {
        let data: { [key: string]: ClientInvoices } = {}

        for (const doc of timesheets.data || []) {
            for (const projectId in doc.projects) {
                const project = (projects.data || []).find(
                    (project: CompanyProjectDoc) => project.id === projectId
                )
                if (project) {
                    if (!data[project.clientId]) {
                        data[project.clientId] = {
                            ...((clients.data || []).find(
                                (client) => client.id === project.clientId
                            ) as ReadCompentClientDoc),
                            projects: [],
                        }
                    }

                    data[project.clientId].projects.push({
                        ...project,
                        timesheet: doc.projects[projectId],
                    })
                }
            }
        }

        setData(data)
    }, [timesheets.data])

    return (
        <ContentLayout
            title="Faktureringsöversikt"
            actionBar={
                <Box>
                    <TextField
                        type="month"
                        sx={{ maxWidth: isBelowMdScreen ? 130 : null }}
                        inputProps={{
                            max: getCurrentYearMonth(),
                        }}
                        value={monthYear}
                        onChange={(e) => setMonthYear(e.target.value)}
                        size={isBelowMdScreen ? 'small' : 'medium'}
                        required
                    />
                </Box>
            }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Uppdragstitel</TableCell>
                        <TableCell>Timarvode</TableCell>
                        <TableCell>Rapporterade timmar</TableCell>
                        <TableCell>Totalt belopp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(data).map((key: string) => (
                        <Row key={data[key].id} data={data[key]} />
                    ))}
                </TableBody>
            </Table>
            {Object.keys(data).length <= 0 && (
                <Box sx={{ textAlign: 'center', p: 4 }}>
                    <Typography>
                        Skapa en tidrapport för att se faktureringsöversikt.
                    </Typography>
                </Box>
            )}
        </ContentLayout>
    )
}

function Row({ data }: { data: ClientInvoices }) {
    function sumHoursInTimesheet(timesheet: {
        [day: number]: DayInfo
    }): number {
        let totalHours = 0

        for (const dayInfo of Object.values(timesheet)) {
            if (dayInfo.hours) {
                totalHours += dayInfo.hours
            }
        }

        return totalHours
    }

    return (
        <>
            {data.projects.map((project) => (
                <TableRow key={project.id}>
                    <TableCell component="th" scope="row">
                        {project.title}
                    </TableCell>
                    <TableCell>{project.price || 'Ej angivet'}</TableCell>
                    <TableCell>
                        {sumHoursInTimesheet(project.timesheet)}
                    </TableCell>
                    <TableCell>
                        {project?.price
                            ? `${
                                  (project?.price || 0) *
                                  sumHoursInTimesheet(project.timesheet)
                              }} SEK`
                            : '-'}
                    </TableCell>
                </TableRow>
            ))}
        </>
    )
}
