import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://2a6ca82f7183bcf51b667f63d452af48@o4505635956523008.ingest.sentry.io/4505635957637120',
        // Performance Monitoring
        tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
