import { Box, Button, SelectChangeEvent, Typography } from '@mui/material'
import { useState } from 'react'
import FormProject from '../../components/formProject/FormProject'
import {
    CompanyProposalEditables,
    RateType,
} from '../../services/companyProposalService'
import {
    BillingSchedule,
    CompanyProjectEditables,
    useCreateCompanyProject,
} from '../../services/companyProjectService'

interface CreateProjectProps {
    defaultValues?: CompanyProposalEditables
    onCreated?: () => void
}

export default function CreateProject({
    defaultValues,
    onCreated,
}: CreateProjectProps) {
    const companyProject = useCreateCompanyProject()
    const [form, setForm] = useState<CompanyProjectEditables>({
        title: '',
        description: '',
        clientId: '',
        ...defaultValues,
        rateType: RateType.HOURLY,
        estimatedWorkload: 100,
        billingSchedule: BillingSchedule.MONTHLY,
    })

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<RateType>
    ) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        } as CompanyProjectEditables)
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        await companyProject.mutateAsync(form)
        onCreated && onCreated()
    }

    return (
        <Box
            sx={{
                '> *': {
                    mb: 4,
                },
            }}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <Box>
                <Typography variant="h3" component="h2">
                    Skapa nytt uppdrag
                </Typography>
            </Box>
            <FormProject
                values={form}
                onChange={handleChange}
                onClientChange={(clientId) => {
                    setForm({ ...form, clientId })
                }}
            />
            <Button type="submit" variant="contained" size="large" fullWidth>
                Spara
            </Button>
        </Box>
    )
}
