import {
    Box,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { useState } from 'react'
import { getIdFromDate } from './utils/getIdFromDate'
import {
    CompanyProjectDoc,
    useCompanyProjects,
} from '../../services/companyProjectService'
import {
    CompanyTimesheetUserEditables,
    CompanyTimesheetUserProjects,
    DayInfo,
    useCreateCompanyTimesheetUser,
} from '../../services/companyTimesheetUserService'

interface WeeklyTimesheetProps {
    dates: DayInfo[]
    defaultTimesheet: CompanyTimesheetUserProjects
    onFinished: () => void
}

export default function WeeklyTimesheet({
    dates,
    defaultTimesheet = {},
    onFinished,
}: WeeklyTimesheetProps) {
    const projects = useCompanyProjects()
    const createTimesheet = useCreateCompanyTimesheetUser()
    const [timesheet, setTimesheet] =
        useState<CompanyTimesheetUserProjects>(defaultTimesheet)
    const theme = useTheme()

    const handleSave = async (): Promise<void> => {
        await createTimesheet.mutateAsync({
            yearMonth: getIdFromDate(dates[0].date),
            timesheet: {
                projects: { ...timesheet },
            } as CompanyTimesheetUserEditables,
        })
        onFinished()
    }

    if ((projects?.data || []).length <= 0) {
        return (
            <Box>
                Inga uppdrag. Lägg till minst ett uppdrag för att rapportera
                tid.
            </Box>
        )
    }

    return (
        <Box>
            <Box>
                <Typography
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '20px', // Adjust font-size for small screens
                        },
                    }}
                    variant="h3"
                    component="h2"
                >{`Tidrapport: W${dates[0].weekNumber}`}</Typography>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {dates.map((dayInfo: DayInfo) => (
                            <TableCell
                                key={dayInfo.weekDay}
                                align="center"
                            >{`${dayInfo.weekDay.substring(3, 0)} ${new Date(
                                dayInfo.date
                            ).getDate()}/${
                                new Date(dayInfo.date).getMonth() + 1
                            }`}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(projects?.data || []).map(
                        (contract: CompanyProjectDoc) => (
                            <TableRow key={contract.id}>
                                <TableCell component="th" scope="row">
                                    {contract.title}
                                </TableCell>
                                {dates.map((dayInfo: DayInfo) => (
                                    <TableCell
                                        key={contract.id + '-' + dayInfo.date}
                                        sx={{ width: 70 }}
                                    >
                                        <TextField
                                            size="small"
                                            type="text"
                                            value={
                                                timesheet[
                                                    contract.id as string
                                                ]?.[dayInfo.date.getDate()]
                                                    ?.hours || null
                                            }
                                            onChange={(e) => {
                                                setTimesheet({
                                                    ...timesheet,
                                                    [contract.id as string]: {
                                                        ...timesheet[
                                                            contract.id as string
                                                        ],
                                                        [dayInfo.date.getDate()]:
                                                            {
                                                                ...dayInfo,
                                                                hours: Number(
                                                                    e.target
                                                                        .value
                                                                ),
                                                            },
                                                    },
                                                })
                                            }}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
            <Stack spacing={2} direction="row" sx={{ float: 'right', p: 2 }}>
                <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                        onFinished()
                    }}
                >
                    Avbryt
                </Button>
                <Button variant="contained" size="large" onClick={handleSave}>
                    Spara
                </Button>
            </Stack>
        </Box>
    )
}
