import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Hidden,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import useAuth from '../../hooks/UseAuth'
import { grey } from '@mui/material/colors'
import { db } from '../../libs/firebase'
import { addDoc, collection } from 'firebase/firestore'
import useSnackbar from '../../hooks/UseSnackbar'

const roles = [
    'Anställd konsult',
    'Frilansande konsult',
    'Konsultmäklare',
    'Konsultsäljare',
    'Konsultchef',
    'Projektledare',
    'Annat',
    'Jag arbetar inte i konsultbranschen',
]

const branches = [
    'IT/Teknik',
    'Ekonomi/Finans',
    'HR/Personal',
    'Marknadsföring/Försäljning',
    'Management/Konsulting',
    'Hälsa/Vård',
    'Utbildning/Forskning',
    'Bygg/Arkitektur',
    'Juridik',
    'Media/Kommunikation',
    'Turism/Resor',
    'Transport/Logistik',
    'Energi/Miljö',
    'Mode/Skönhetsvård',
    'Sport/Fritid',
    'Livsmedel',
    'Fastighet/Förvaltning',
    'Industri/Produktion',
    'Underhållning',
    'Övrigt',
]

export default function BetaSignup() {
    const snackbar = useSnackbar()
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [role, setRole] = useState<string>('')
    const [hasProject, setHasProject] = useState<boolean | null>(null)
    const [bransch, setBransch] = useState<string>('')

    useEffect(() => {
        if (role === 'anställd-konsult' || role === 'frilansande-konsult') {
            if (hasProject === null) {
                setHasProject(false)
            }
        } else {
            if (hasProject !== null) {
                setHasProject(null)
            }
        }
    }, [role])
    // Eventhandler for submitting
    const handleSubmit = async (event: any) => {
        event.preventDefault()
        try {
            await addDoc(collection(db, 'beta'), {
                name,
                email,
                role,
                bransch,
                hasProject,
            })
            snackbar.openSnackbar(
                'Tack för din ansökan! Vi återkommer när det är din tur.'
            )
        } catch (error) {
            // Some error
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box sx={{ maxWidth: 700, textAlign: 'center', px: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Typography sx={{ mb: 2 }} variant="h1">
                        Gå med i BETA-programmet
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="subtitle1">
                        Testa uppdragsmatchning i världsklass. Vår
                        automatiserade konsultmäklare befinner sig för
                        närvarande i betastadiet.
                    </Typography>
                    <Typography sx={{ mb: 2, fontWeight: 600 }} variant="body2">
                        OBS! Tillgången till BETA-programmet är för närvarande
                        begränsad på grund av hög efterfrågan. Vi bjuder
                        successivt in nya registreringar.
                    </Typography>
                    <Grid sx={{ textAlign: 'left' }} item xs={12} md={12}>
                        <TextField
                            type="text"
                            label="Namn"
                            variant="filled"
                            fullWidth
                            margin="normal"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            type="email"
                            label="E-post"
                            variant="filled"
                            fullWidth
                            margin="normal"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormControl
                            required
                            variant="filled"
                            margin="normal"
                            fullWidth
                        >
                            <InputLabel id="role-label">Roll</InputLabel>
                            <Select
                                labelId="role-label"
                                id="role"
                                value={role}
                                label="Roll"
                                onChange={(event: SelectChangeEvent) => {
                                    setRole(event.target.value as string)
                                }}
                            >
                                <MenuItem value="">
                                    <em>Välj en roll...</em>
                                </MenuItem>
                                {roles.map((role, index) => (
                                    <MenuItem
                                        key={index}
                                        value={role
                                            .toLowerCase()
                                            .replace(/\s+/g, '-')}
                                    >
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {(role === 'anställd-konsult' ||
                            role === 'frilansande-konsult') && (
                            <FormControlLabel
                                sx={{ my: 2 }}
                                control={
                                    <Checkbox
                                        checked={hasProject === true}
                                        onChange={(e) => {
                                            setHasProject(e.target.checked)
                                        }}
                                    />
                                }
                                label="Arbetar du för närvarande på ett konsultuppdrag?"
                            />
                        )}
                        <FormControl
                            required
                            variant="filled"
                            margin="normal"
                            fullWidth
                        >
                            <InputLabel id="bransch-label">Bransch</InputLabel>
                            <Select
                                labelId="bransch-label"
                                value={bransch}
                                label="Bransch"
                                onChange={(event: SelectChangeEvent) => {
                                    setBransch(event.target.value as string)
                                }}
                            >
                                <MenuItem value="">
                                    <em>Välj en bransch...</em>
                                </MenuItem>
                                {branches.map((bransch, index) => (
                                    <MenuItem
                                        key={index}
                                        value={bransch
                                            .toLowerCase()
                                            .replace(/\s+/g, '-')}
                                    >
                                        {bransch}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            sx={{ mt: 4 }}
                            type="submit"
                            variant="contained"
                            size="large"
                            fullWidth
                        >
                            Skicka
                        </Button>
                        <Link
                            href="https://konsio.se"
                            sx={{
                                display: 'block',
                                mt: 2,
                                textAlign: 'center',
                            }}
                        >
                            Tillbaka till startsida
                        </Link>
                        <Typography
                            sx={{
                                mt: 4,
                                fontSize: 12,
                                color: grey[700],
                                textAlign: 'center',
                            }}
                            variant="body2"
                        >
                            Genom att registrera dig godkänner du
                            användarvillkoren och sekretesspolicyn, däribland
                            användning av cookies.
                        </Typography>
                    </Grid>
                </form>
            </Box>
            <Hidden mdDown>
                <Box
                    sx={{
                        width: '500px',
                        position: 'absolute',
                        bottom: '-80px',
                        right: '-80px',
                    }}
                    component="img"
                    src="/logos.png"
                />
            </Hidden>
        </Box>
    )
}
