import { DayInfo, Weekday } from '../../../services/companyTimesheetUserService'
import { days } from '../types'

function getWeekNumber(date: Date): number {
    const tempDate = new Date(date.valueOf())
    tempDate.setHours(0, 0, 0, 0)
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7))
    const week1 = new Date(tempDate.getFullYear(), 0, 4)
    const weekNumber =
        1 +
        Math.round(
            ((tempDate.valueOf() - week1.valueOf()) / 86400000 -
                3 +
                ((week1.getDay() + 6) % 7)) /
                7
        )
    return weekNumber
}

function getWeekday(date: Date): Weekday {
    return days[(date.getDay() + 6) % 7]
}

export function getAllDatesInMonth(month: number, year: number): DayInfo[] {
    const datesInMonth: DayInfo[] = []
    const firstDay = new Date(year, month, 1)
    const lastDay = new Date(year, month + 1, 0)

    for (
        let date = firstDay;
        date <= lastDay;
        date.setDate(date.getDate() + 1)
    ) {
        const currDate = new Date(date)
        datesInMonth.push({
            date: currDate,
            weekNumber: getWeekNumber(currDate),
            weekDay: getWeekday(currDate),
        })
    }

    return datesInMonth
}
