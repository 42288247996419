import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
    TextareaAutosize,
    Typography,
} from '@mui/material'
import { ReactNode, useState } from 'react'
import {
    CompanyProposalEditables,
    RateType,
} from '../../services/companyProposalService'
import ClientField from './ClientField'

interface FormProposalProps {
    values: CompanyProposalEditables
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onClientChange: (clientId: string) => void
}

export default function FormProposal({
    values,
    onChange,
    onClientChange,
}: FormProposalProps) {
    const [hasClientCustomer, setHasClientCustomer] = useState<boolean>(
        values?.customer != undefined
    )
    return (
        <Stack direction="column" spacing={4}>
            <Typography variant="h6">Beskrivning av uppdrag</Typography>
            <TextField
                type="text"
                name="title"
                label="Uppdragstitel"
                required
                variant="filled"
                fullWidth
                onChange={onChange}
                value={values.title}
            />
            <TextField
                name="link"
                type="url"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Link />
                        </InputAdornment>
                    ),
                }}
                label="Länk till uppdragsannons"
                variant="filled"
                onChange={onChange}
                fullWidth
                value={values.link}
            />
            <TextField
                multiline={true}
                rows={4}
                type="textarea"
                name="description"
                label="Uppdragsbeskrivning"
                variant="filled"
                fullWidth
                onChange={onChange}
                value={values.description}
            />
            <Typography variant="h6">Klient och slutkund</Typography>
            <ClientField
                defaultClientId={values.clientId}
                onChange={(clientId: string) => {
                    onClientChange(clientId)
                }}
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={hasClientCustomer}
                        onChange={() =>
                            setHasClientCustomer(!hasClientCustomer)
                        }
                    />
                }
                label="Är klient och slutkund olika företag?"
            />
            {hasClientCustomer && (
                <TextField
                    type="text"
                    name="customer"
                    label="Slutkund"
                    variant="filled"
                    fullWidth
                    onChange={onChange}
                    value={values.customer}
                />
            )}
            <Typography variant="h6">Uppdragsinformation</Typography>
            <Stack direction="row" spacing={2}>
                <TextField
                    type="date"
                    name="startDate"
                    label="Startdatum"
                    variant="filled"
                    fullWidth
                    onChange={onChange}
                    value={values.startDate}
                />
                <TextField
                    type="date"
                    name="endDate"
                    label="Slutdatum"
                    variant="filled"
                    fullWidth
                    onChange={onChange}
                    value={values.endDate}
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField
                    type="number"
                    name="price"
                    label="Arvode"
                    variant="filled"
                    fullWidth
                    onChange={onChange}
                    value={values.price}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">SEK</InputAdornment>
                        ),
                    }}
                />
                <FormControl sx={{ width: 200 }} variant="filled">
                    <InputLabel id="inputLabel-type">Avgiftstyp</InputLabel>
                    <Select
                        name="rateType"
                        labelId="inputLabel-type"
                        value={values.rateType}
                        onChange={(e) => {
                            const inputEvent =
                                e as unknown as React.ChangeEvent<HTMLInputElement>
                            onChange(inputEvent)
                        }}
                        autoWidth
                        label="Avgiftstyp"
                    >
                        <MenuItem value={RateType.HOURLY}>per timme</MenuItem>
                        <MenuItem value={RateType.FLAT}>fast pris</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
        </Stack>
    )
}
