import {
    Avatar,
    Box,
    Button,
    Chip,
    Collapse,
    Divider,
    Hidden,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    Link,
} from '@mui/material'
import ContentLayout from '../../components/contentLayout/ContentLayout'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import AddTaskIcon from '@mui/icons-material/AddTask'
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import TextFieldTags from '../../components/textFieldTags/TextFieldTags'
import MiniSearch, { SearchResult } from 'minisearch'
import { ModalCentered } from '../../components'
import CreateProposal from '../proposals/CreateProposal'
import { Lead } from './types'
import { green, red, yellow } from '@mui/material/colors'
import getMatchRank from './utils/GetMatchRank'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export default function Leads() {
    const theme = useTheme()
    const isBelowMdScreen = useMediaQuery('(max-width:850px)')
    const [cities, setCities] = useState<string[]>([])
    const [keywords, setKeywords] = useState<string[]>([])
    const [results, setResults] = useState<SearchResult[]>([])
    const [showAddResult, setShowAddResult] = useState<SearchResult | null>(
        null
    )
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false)

    const miniSearch = useMemo(() => {
        return new MiniSearch({
            fields: ['body'],
            storeFields: ['title', 'url'],
            searchOptions: {
                boost: { title: 2 },
                fuzzy: 0.2,
            },
        })
    }, [])

    useEffect(() => {
        const setupSearchIndex = async () => {
            return await fetch('https://konsio.se/assets/ads.json')
                .then((data) => data.json())
                .then((leads: Lead[]) => {
                    if (miniSearch.documentCount > 0) {
                        miniSearch.removeAll()
                    }
                    const indexJobs = leads.map(
                        (lead: Lead, index: number) => ({
                            ...lead,
                            id: index,
                        })
                    )
                    miniSearch.addAll(indexJobs)
                })
        }
        setupSearchIndex()
    }, [])

    useEffect(() => {
        const results = miniSearch.search(keywords.concat(cities).join(' '))
        if (keywords.length > 0) {
            const leadSearchesJSON = localStorage.getItem('leadSearches')
            const search = leadSearchesJSON ? JSON.parse(leadSearchesJSON) : {}
            localStorage.setItem(
                'leadSearches',
                JSON.stringify({
                    keywords: search.keywords
                        ? Array.from(
                              new Set(keywords.concat(search.keywords))
                          ).slice(0, 5)
                        : keywords,
                    cities: search.cities
                        ? Array.from(
                              new Set(cities.concat(search.cities))
                          ).slice(0, 5)
                        : cities,
                })
            )
        }
        setResults(results.slice(0, 50))
    }, [keywords, cities])

    const getLatestSearch = (): ReactNode => {
        const leadSearchesJSON = localStorage.getItem('leadSearches')
        const search = leadSearchesJSON ? JSON.parse(leadSearchesJSON) : {}
        if (
            (search && (search?.keywords || {}).length > 0) ||
            (search?.cities || {}).length > 0
        ) {
            return (
                <Stack direction="row" spacing={2} sx={{ overflow: 'hidden' }}>
                    {search.keywords.map((keyword: string) => (
                        <Chip
                            key={keyword}
                            label={keyword}
                            color="secondary"
                            onClick={() => {
                                setKeywords([...keywords, keyword])
                            }}
                        />
                    ))}
                    {search.cities.map((city: string) => (
                        <Chip
                            key={city}
                            label={city}
                            color="secondary"
                            onClick={() => {
                                setCities([...cities, city])
                            }}
                        />
                    ))}
                </Stack>
            )
        }

        return (
            <Typography variant="body1">
                Du har inga tidigare sökningar.
            </Typography>
        )
    }

    if (keywords.length <= 0 && cities.length <= 0) {
        return (
            <ContentLayout
                title="Leads"
                actionBar={
                    <Button
                        variant="text"
                        onClick={() => setShowDisclaimer(true)}
                        startIcon={<InfoOutlinedIcon />}
                    >
                        Ansvarsfriskrivning
                    </Button>
                }
            >
                {showDisclaimer && (
                    <ModalCentered
                        open={showDisclaimer}
                        onClose={() => setShowDisclaimer(false)}
                    >
                        <div>
                            <h1>Ansvarsfriskrivning</h1>
                            <p>
                                Vår sökmotor för konsultuppdrag tillhandahåller
                                en tjänst för att aggregatera och presentera
                                konsultuppdrag från olika källor. Observera att
                                vi inte har något samarbete, partnerskap eller
                                direkt förhållande med de webbplatser eller
                                företag som publicerar dessa uppdrag.
                            </p>

                            <p>
                                All information om konsultuppdragen som visas på
                                vår webbplats kommer från externa källor, och vi
                                kan inte garantera dess fullständighet,
                                noggrannhet eller aktualitet. Det är användarens
                                ansvar att verifiera informationen och bedöma
                                pålitligheten hos varje enskild källa.
                            </p>

                            <p>
                                Vänligen notera att vi inte ansvarar för några
                                felaktigheter, försummelse eller skador som kan
                                uppstå på grund av användningen av informationen
                                på vår webbplats. Användare uppmanas att själva
                                utvärdera konsultuppdragen och agera i enlighet
                                med sina egna bedömningar och försiktighet.
                            </p>

                            <p>
                                Om du väljer att ansöka om ett konsultuppdrag
                                från en extern källa, kom ihåg att du
                                interagerar med den specifika webbplatsen eller
                                företaget på egen risk. Vi rekommenderar att du
                                noggrant läser igenom villkor, policyer och
                                eventuella avtal från dessa externa källor innan
                                du fortsätter.
                            </p>

                            <p>
                                Vänligen var medveten om att våra tjänster
                                endast är en samlingspunkt för information och
                                vi ansvarar inte för de erbjudanden,
                                affärsrelationer eller andra transaktioner som
                                kan uppstå mellan användare och externa källor.
                            </p>

                            <p>
                                Vid frågor eller förtydliganden om vår
                                ansvarsfriskrivning är du välkommen att kontakta
                                oss.
                            </p>
                        </div>
                    </ModalCentered>
                )}
                <Stack
                    sx={{ p: 2 }}
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                >
                    <TextFieldTags
                        values={keywords}
                        onTagsChange={(values: string[]) => {
                            setKeywords(values)
                        }}
                        label="Sökord"
                    />
                    <TextFieldTags
                        values={cities}
                        onTagsChange={(values: string[]) => {
                            setCities(values)
                        }}
                        label="Städer"
                    />
                </Stack>
                <Typography sx={{ ml: 2, mt: 2 }} variant="h6">
                    Sökhistorik
                </Typography>
                <Box sx={{ p: 2 }}>{getLatestSearch()}</Box>
                <Typography sx={{ ml: 2, mt: 2 }} variant="h6">
                    Populära sökningar
                </Typography>
                {!isBelowMdScreen ? (
                    <Fragment>
                        <Stack direction="row" spacing={4} sx={{ p: 2 }}>
                            <Paper
                                sx={{
                                    width: '50%',
                                    maxWidth: 400,
                                    background: theme.palette.grey[200],
                                    border: '1px solid',
                                    borderColor: theme.palette.grey[400],
                                    ':hover': {
                                        border: `2px solid ${theme.palette.secondary.main}`,
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setKeywords(['utvecklare'])}
                            >
                                <Box sx={{ mb: 2, p: 2 }}>
                                    <Typography variant="h6">
                                        Utvecklare
                                    </Typography>
                                    <Typography variant="body2">
                                        Yrke
                                    </Typography>
                                </Box>
                                <Divider />
                                <svg
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        d="M 0 80 Q 10 60 20 70 Q 30 80 40 70 Q 50 60 60 50 Q 70 40 80 50 Q 90 60 100 50 Q 110 40"
                                        stroke={theme.palette.secondary.main}
                                        strokeWidth="2"
                                        fill="transparent"
                                    />
                                </svg>
                            </Paper>
                            <Paper
                                sx={{
                                    width: '50%',
                                    maxWidth: 400,
                                    background: theme.palette.grey[200],
                                    border: '1px solid',
                                    borderColor: theme.palette.grey[400],
                                    ':hover': {
                                        border: `2px solid ${theme.palette.secondary.main}`,
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setKeywords(['projektledare'])}
                            >
                                <Box sx={{ mb: 2, p: 2 }}>
                                    <Typography variant="h6">
                                        Projektledare
                                    </Typography>
                                    <Typography variant="body2">
                                        Yrke
                                    </Typography>
                                </Box>
                                <Divider />
                                <svg
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        d="M 0 90 Q 10 70 20 80 Q 30 90 40 80 Q 50 70 60 80 Q 70 90 80 70 Q 90 50 100 60 Q 110 70"
                                        stroke={theme.palette.secondary.main}
                                        strokeWidth="2"
                                        fill="transparent"
                                    />
                                </svg>
                            </Paper>
                            <Paper
                                sx={{
                                    width: '50%',
                                    maxWidth: 400,
                                    background: theme.palette.grey[200],
                                    border: '1px solid',
                                    borderColor: theme.palette.grey[400],
                                    ':hover': {
                                        border: `2px solid ${theme.palette.secondary.main}`,
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setKeywords(['controller'])}
                            >
                                <Box sx={{ mb: 2, p: 2 }}>
                                    <Typography variant="h6">
                                        Controller
                                    </Typography>
                                    <Typography variant="body2">
                                        Yrke
                                    </Typography>
                                </Box>
                                <Divider />
                                <svg
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        d="M 0 50 Q 10 70 20 50 Q 30 20 40 50 Q 50 70 60 50 Q 70 30 80 50 Q 90 20 100 50 Q 110 30"
                                        stroke={theme.palette.secondary.main}
                                        strokeWidth="2"
                                        fill="transparent"
                                    />
                                </svg>
                            </Paper>
                        </Stack>
                    </Fragment>
                ) : (
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            p: 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Chip
                            label="React"
                            color="primary"
                            onClick={() => {
                                setKeywords([...keywords, 'React'])
                            }}
                        />
                        <Chip
                            label="Projektledare"
                            color="primary"
                            onClick={() => {
                                setKeywords([...keywords, 'Projektledare'])
                            }}
                        />
                        <Chip
                            label="Controller"
                            color="primary"
                            onClick={() => {
                                setKeywords([...keywords, 'Controller'])
                            }}
                        />
                        <Chip
                            label="Backend"
                            color="primary"
                            onClick={() => {
                                setKeywords([...keywords, 'Backend'])
                            }}
                        />
                    </Stack>
                )}
            </ContentLayout>
        )
    }

    const getMatchColor = (score: number) => {
        const rank = getMatchRank(
            score,
            results[results.length - 1].score,
            results[0].score
        )
        if (rank === 'Högt') {
            return green
        } else if (rank === 'Mellan') {
            return yellow
        }

        return red
    }

    if (isBelowMdScreen) {
        return (
            <ContentLayout
                title="Leads"
                actionBar={
                    <Button
                        variant="text"
                        onClick={() => setShowDisclaimer(true)}
                        startIcon={<InfoOutlinedIcon />}
                    >
                        Ansvarsfriskrivning
                    </Button>
                }
            >
                {showAddResult && (
                    <ModalCentered
                        open={showAddResult != null}
                        onClose={() => setShowAddResult(null)}
                    >
                        <Fragment>
                            <CreateProposal
                                title={showAddResult.title}
                                url={showAddResult.url}
                                onSubmitted={() => setShowAddResult(null)}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 2,
                                    p: 2,
                                    background: theme.palette.grey[200],
                                }}
                            >
                                <Box sx={{ mr: 2 }}>
                                    <InfoOutlinedIcon />
                                </Box>
                                <Typography>
                                    Glöm inte att skicka din ansökan via
                                    konsultmäklarens webbplats också.
                                </Typography>
                            </Box>
                        </Fragment>
                    </ModalCentered>
                )}
                {showDisclaimer && (
                    <ModalCentered
                        open={showDisclaimer}
                        onClose={() => setShowDisclaimer(false)}
                    >
                        <div>
                            <h1>Ansvarsfriskrivning</h1>
                            <p>
                                Vår sökmotor för konsultuppdrag tillhandahåller
                                en tjänst för att aggregatera och presentera
                                konsultuppdrag från olika källor. Observera att
                                vi inte har något samarbete, partnerskap eller
                                direkt förhållande med de webbplatser eller
                                företag som publicerar dessa uppdrag.
                            </p>

                            <p>
                                All information om konsultuppdragen som visas på
                                vår webbplats kommer från externa källor, och vi
                                kan inte garantera dess fullständighet,
                                noggrannhet eller aktualitet. Det är användarens
                                ansvar att verifiera informationen och bedöma
                                pålitligheten hos varje enskild källa.
                            </p>

                            <p>
                                Vänligen notera att vi inte ansvarar för några
                                felaktigheter, försummelse eller skador som kan
                                uppstå på grund av användningen av informationen
                                på vår webbplats. Användare uppmanas att själva
                                utvärdera konsultuppdragen och agera i enlighet
                                med sina egna bedömningar och försiktighet.
                            </p>

                            <p>
                                Om du väljer att ansöka om ett konsultuppdrag
                                från en extern källa, kom ihåg att du
                                interagerar med den specifika webbplatsen eller
                                företaget på egen risk. Vi rekommenderar att du
                                noggrant läser igenom villkor, policyer och
                                eventuella avtal från dessa externa källor innan
                                du fortsätter.
                            </p>

                            <p>
                                Vänligen var medveten om att våra tjänster
                                endast är en samlingspunkt för information och
                                vi ansvarar inte för de erbjudanden,
                                affärsrelationer eller andra transaktioner som
                                kan uppstå mellan användare och externa källor.
                            </p>

                            <p>
                                Vid frågor eller förtydliganden om vår
                                ansvarsfriskrivning är du välkommen att kontakta
                                oss.
                            </p>
                        </div>
                    </ModalCentered>
                )}
                <Stack
                    sx={{ p: 2 }}
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                >
                    <TextFieldTags
                        values={keywords}
                        onTagsChange={(values: string[]) => {
                            setKeywords(values)
                        }}
                        label="Sökord"
                    />
                    <TextFieldTags
                        values={cities}
                        onTagsChange={(values: string[]) => {
                            setCities(values)
                        }}
                        label="Städer"
                    />
                </Stack>
                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{ display: 'block', mr: 2 }}
                    >
                        Sökhistorik:
                    </Typography>
                    {getLatestSearch()}
                </Box>
                <List>
                    {results.map((result: SearchResult) => (
                        <>
                            <ListItem key={result.id}>
                                <ListItemText
                                    secondary={result.url}
                                    secondaryTypographyProps={{
                                        display: 'block',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <Link
                                        target="__blank"
                                        href={result.url}
                                        sx={{
                                            color: theme.palette.primary.dark,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {result.title}
                                    </Link>
                                </ListItemText>
                                <ListItemIcon
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => {
                                        setShowAddResult(result)
                                    }}
                                >
                                    <AddTaskIcon fontSize="small" />
                                </ListItemIcon>
                            </ListItem>
                            <Divider />
                        </>
                    ))}
                </List>
                {results.length <= 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 4,
                        }}
                    >
                        <Typography variant="body1">
                            Vi kunde tyvärr inte hitta några sökresultat.
                        </Typography>
                    </Box>
                )}
            </ContentLayout>
        )
    }

    return (
        <ContentLayout
            title="Leads"
            actionBar={
                <Button
                    variant="text"
                    onClick={() => setShowDisclaimer(true)}
                    startIcon={<InfoOutlinedIcon />}
                >
                    Ansvarsfriskrivning
                </Button>
            }
        >
            {showDisclaimer && (
                <ModalCentered
                    open={showDisclaimer}
                    onClose={() => setShowDisclaimer(false)}
                >
                    <div>
                        <h1>Ansvarsfriskrivning</h1>
                        <p>
                            Vår sökmotor för konsultuppdrag tillhandahåller en
                            tjänst för att aggregatera och presentera
                            konsultuppdrag från olika källor. Observera att vi
                            inte har något samarbete, partnerskap eller direkt
                            förhållande med de webbplatser eller företag som
                            publicerar dessa uppdrag.
                        </p>

                        <p>
                            All information om konsultuppdragen som visas på vår
                            webbplats kommer från externa källor, och vi kan
                            inte garantera dess fullständighet, noggrannhet
                            eller aktualitet. Det är användarens ansvar att
                            verifiera informationen och bedöma pålitligheten hos
                            varje enskild källa.
                        </p>

                        <p>
                            Vänligen notera att vi inte ansvarar för några
                            felaktigheter, försummelse eller skador som kan
                            uppstå på grund av användningen av informationen på
                            vår webbplats. Användare uppmanas att själva
                            utvärdera konsultuppdragen och agera i enlighet med
                            sina egna bedömningar och försiktighet.
                        </p>

                        <p>
                            Om du väljer att ansöka om ett konsultuppdrag från
                            en extern källa, kom ihåg att du interagerar med den
                            specifika webbplatsen eller företaget på egen risk.
                            Vi rekommenderar att du noggrant läser igenom
                            villkor, policyer och eventuella avtal från dessa
                            externa källor innan du fortsätter.
                        </p>

                        <p>
                            Vänligen var medveten om att våra tjänster endast är
                            en samlingspunkt för information och vi ansvarar
                            inte för de erbjudanden, affärsrelationer eller
                            andra transaktioner som kan uppstå mellan användare
                            och externa källor.
                        </p>

                        <p>
                            Vid frågor eller förtydliganden om vår
                            ansvarsfriskrivning är du välkommen att kontakta
                            oss.
                        </p>
                    </div>
                </ModalCentered>
            )}
            {showAddResult && (
                <ModalCentered
                    open={showAddResult != null}
                    onClose={() => setShowAddResult(null)}
                >
                    <Fragment>
                        <CreateProposal
                            title={showAddResult.title}
                            url={showAddResult.url}
                            onSubmitted={() => setShowAddResult(null)}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                                p: 2,
                                background: theme.palette.grey[200],
                            }}
                        >
                            <Box sx={{ mr: 2 }}>
                                <InfoOutlinedIcon />
                            </Box>
                            <Typography>
                                Glöm inte att skicka din ansökan via
                                konsultmäklarens webbplats också.
                            </Typography>
                        </Box>
                    </Fragment>
                </ModalCentered>
            )}
            <Stack
                sx={{ p: 2 }}
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
            >
                <TextFieldTags
                    values={keywords}
                    onTagsChange={(values: string[]) => {
                        setKeywords(values)
                    }}
                    label="Sökord"
                />
                <TextFieldTags
                    values={cities}
                    onTagsChange={(values: string[]) => {
                        setCities(values)
                    }}
                    label="Städer"
                />
            </Stack>
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <Typography variant="body2" sx={{ display: 'block', mr: 2 }}>
                    Sökhistorik:
                </Typography>
                {getLatestSearch()}
            </Box>
            <TableContainer sx={{ maxHeight: '75vh' }}>
                <Table sx={{ width: '100%' }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <Hidden mdDown>
                                <TableCell></TableCell>
                            </Hidden>
                            <TableCell>Uppdrag</TableCell>
                            <Hidden mdDown>
                                <TableCell align="center">ResultRank</TableCell>
                            </Hidden>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflow: 'auto' }}>
                        {results.map((result: SearchResult, index: number) => (
                            <TableRow
                                key={result.id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <Hidden mdDown>
                                    <TableCell align="left">
                                        <Avatar
                                            variant="rounded"
                                            sx={(theme) => ({
                                                background:
                                                    theme.palette.grey[300],
                                                p: 3,
                                                textAlign: 'center',
                                            })}
                                        >
                                            <Box
                                                sx={(theme) => ({
                                                    color: theme.palette
                                                        .grey[600],
                                                    fontWeight: 600,
                                                })}
                                            >
                                                {index + 1}
                                            </Box>
                                        </Avatar>
                                    </TableCell>
                                </Hidden>
                                <TableCell
                                    sx={{
                                        maxWidth: '400px',
                                    }}
                                    component="th"
                                    scope="row"
                                >
                                    <Box
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 600 }}>
                                            {result.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={(theme) => ({
                                                fontSize: '0.8rem',
                                                color: theme.palette.grey[600],
                                            })}
                                        >
                                            {result.url}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <Hidden mdDown>
                                    <TableCell align="center">
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Box
                                                sx={{
                                                    borderRadius: 100,
                                                    width: '60px',
                                                    border: '2px solid',
                                                    borderColor: getMatchColor(
                                                        result.score
                                                    )[600],
                                                    p: 0.5,
                                                    fontWeight: 600,
                                                    color: getMatchColor(
                                                        result.score
                                                    )[800],
                                                    background: getMatchColor(
                                                        result.score
                                                    )[50],
                                                }}
                                            >
                                                {getMatchRank(
                                                    result.score,
                                                    results[results.length - 1]
                                                        .score,
                                                    results[0].score
                                                )}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </Hidden>
                                <TableCell>
                                    <Stack
                                        spacing={1}
                                        direction="row"
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            onClick={() => {
                                                setShowAddResult(result)
                                            }}
                                            size="small"
                                            variant="outlined"
                                            startIcon={<AddTaskIcon />}
                                        >
                                            Anbud
                                        </Button>
                                        <Button
                                            component="a"
                                            href={result.url}
                                            target="__blank"
                                            size="small"
                                            variant="contained"
                                            startIcon={<OpenInNewIcon />}
                                        >
                                            Besök
                                        </Button>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {results.length <= 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 4,
                        }}
                    >
                        <Typography variant="body1">
                            Vi kunde tyvärr inte hitta några sökresultat.
                        </Typography>
                    </Box>
                )}
            </TableContainer>
        </ContentLayout>
    )
}
