import { Timestamp, addDoc, collection, getDocs } from 'firebase/firestore'
import { COLLECTION } from './companyService'
import { db } from '../libs/firebase'
import useSnackbar from '../hooks/UseSnackbar'
import { useUserData } from './userService'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const SUB_COLLECTION = 'clients'

export interface CompanyClientEditables {
    /** Estimated workload in percentage 0-100 */
    name: string
}

interface CompanyClientSysVars {
    createdAt: Timestamp
}

export interface CompanyClientDoc
    extends CompanyClientEditables,
        CompanyClientSysVars {}

export interface ReadCompentClientDoc extends CompanyClientDoc {
    /** Not stored in DB but used as reference on fetch */
    id: string
}

export const useCompanyClients = () => {
    const user = useUserData()

    return useQuery(
        [SUB_COLLECTION],
        async () => await requests.getClients(user.data?.companyId || ''),
        {
            enabled: user.data?.companyId != undefined,
            cacheTime: Infinity,
        }
    )
}

export const useCreateCompanyClient = () => {
    const snackbar = useSnackbar()
    const user = useUserData()
    const queryClient = useQueryClient()

    return useMutation(
        (client: CompanyClientEditables) =>
            requests.createClient(user.data?.companyId || '', client),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SUB_COLLECTION])
                snackbar.openSnackbar('Klient sparades!')
            },
            onError: () => {
                snackbar.openSnackbar(
                    'Det uppstod ett fel. Klient har inte sparats'
                )
            },
        }
    )
}

const requests = {
    getClients: async (companyId: string): Promise<ReadCompentClientDoc[]> => {
        const docs = await getDocs(
            collection(db, COLLECTION, companyId, SUB_COLLECTION)
        )
        return docs.docs.map(
            (doc) =>
                ({
                    id: doc.id,
                    ...doc.data(),
                }) as ReadCompentClientDoc
        )
    },
    createClient: async (companyId: string, client: CompanyClientEditables) => {
        if (companyId.length <= 0) throw Error('Inget företagsid')
        const doc: CompanyClientDoc = {
            createdAt: Timestamp.fromDate(new Date()),
            ...client,
        }
        return await addDoc(
            collection(db, COLLECTION, companyId, SUB_COLLECTION),
            doc
        )
    },
}

export default requests
