import { createTheme } from '@mui/material'
import { orange } from '@mui/material/colors'

// https://colorhunt.co/palette/454545ff6000ffa559ffe6c7
export const theme = createTheme({
    palette: {
        primary: {
            main: '#222831',
            light: '#393E46',
        },
        secondary: {
            dark: orange[600],
            main: orange[400],
            light: orange[200],
        },
        text: {
            primary: '#1A1A1A',
            secondary: '#4E4E4E',
            disabled: '#A6A6A6',
        },
        background: {
            default: '#F7F7F7',
            paper: '#FFFFFF',
        },
    },
    typography: {
        h1: {
            fontFamily: '"LogoFont", sans-serif',
            fontWeight: 700,
            fontSize: '3rem',
        },
        h2: {
            fontFamily: '"LogoFont", sans-serif',
            fontWeight: 700,
            fontSize: '2.5rem',
        },
        h3: {
            fontFamily: '"LogoFont", sans-serif',
            fontWeight: 600,
            fontSize: '2rem',
        },
        h4: {
            fontFamily: '"LogoFont", sans-serif',
            fontWeight: 600,
            fontSize: '1.75rem',
        },
        h5: {
            fontFamily: '"LogoFont", sans-serif',
        },
        h6: {
            fontFamily: '"LogoFont", sans-serif',
        },
        body1: {
            fontWeight: 400,
            fontSize: '1rem',
        },
        body2: {
            fontWeight: 400,
            fontSize: '0.875rem',
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    borderSpacing: '0 8px',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600,
                    paddingTop: '12px',
                    paddingBottom: '12px',
                },
                body: {
                    paddingTop: '12px',
                    paddingBottom: '12px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F7F7F7',
                    boxShadow:
                        '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08)',
                },
                elevation1: {
                    boxShadow:
                        '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                containedPrimary: {
                    color: '#FFFFFF',
                },
            },
            defaultProps: {
                variant: 'contained',
                color: 'primary',
            },
        },
    },
})
