import {
    User,
    UserCredential,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut as signOutFirebase,
} from 'firebase/auth'
import { createContext, useEffect, useState } from 'react'
import { auth } from '../libs/firebase'
import useSnackbar from '../hooks/UseSnackbar'
import companyService from '../services/companyService'
import userService, { useUserMutation } from '../services/userService'
import companyUserService from '../services/companyUserService'

interface AuthContextProps {
    isLoading: boolean
    user: User | null
    signUpPassword: (
        name: string,
        email: string,
        password: string,
        role: string,
        bransch: string,
        hasProject: boolean | null
    ) => Promise<void>
    signIn: (email: string, password: string) => Promise<void>
    signOut: () => Promise<void>
    resetPassword: (email: string) => Promise<void>
}

export const AuthContext = createContext<AuthContextProps>({
    isLoading: false,
    user: null,
    signUpPassword: async (_e: string, _p: string) => {},
    signIn: async (_e: string, _p: string) => {},
    signOut: async () => {},
    resetPassword: async (_e: string) => {},
})

const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [user, setUser] = useState<User | null>(null)
    const snackbar = useSnackbar()
    const userMutation = useUserMutation()

    // Eventlistener for auth changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (newUser: User | null) => {
            setIsLoading(true)
            if (newUser) setUser(newUser)
            else setUser(null)
            setIsLoading(false)
        })
        // Cleanup subscription on unmount
        return () => unsubscribe()
    }, [])

    const signUpPassword = async (
        name: string,
        email: string,
        password: string,
        role: string,
        bransch: string,
        hasProject: boolean | null
    ): Promise<void> => {
        try {
            const user = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            )

            const companyResponse = await companyService.createCompany()
            await userMutation.mutateAsync({
                userId: user.user.uid,
                userName: name,
                companyId: companyResponse.id,
                role,
                bransch,
                hasProject,
            })
            await companyUserService.createUser(
                user.user.uid,
                companyResponse.id
            )
            // For conversion tracking
            const divElement = document.createElement('div')
            divElement.id = 'new-user'
            document.body.appendChild(divElement)
        } catch (error: any) {
            snackbar.openSnackbar(
                error.message.includes('auth/')
                    ? error.message
                    : 'Ett fel har uppstått.'
            )
        }
    }

    const signIn = async (email: string, password: string): Promise<void> => {
        try {
            const userCredential: UserCredential =
                await signInWithEmailAndPassword(auth, email, password)
            setUser(userCredential.user)
        } catch (error: any) {
            snackbar.openSnackbar(error.message)
        }
    }

    const signOut = async (): Promise<void> => {
        try {
            await signOutFirebase(auth)
            setUser(null)
        } catch (error: any) {
            snackbar.openSnackbar(error.message)
        }
    }

    const resetPassword = async (email: string): Promise<void> => {
        try {
            await sendPasswordResetEmail(auth, email)
            snackbar.openSnackbar(
                'En länk för att återställa ditt lösenord har skickats till din e-post.'
            )
        } catch (error: any) {
            snackbar.openSnackbar(error.message)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                isLoading,
                user,
                signUpPassword,
                signIn,
                signOut,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
