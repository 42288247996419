import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../libs/firebase'
import useAuth from '../hooks/UseAuth'
import {
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query'

const COLLECTION = 'users'

export type UserDoc = {
    name: string
    companyId: string
    signUpDetails: {
        role: string
        bransch: string
        hasProject: boolean | null
    }
}

export const useUserData = (): UseQueryResult<UserDoc> => {
    const user = useAuth()
    return useQuery(
        ['user'],
        async () => await requests.getUser(user.user?.uid || ''),
        {
            enabled: user.user?.uid != undefined,
            cacheTime: Infinity,
        }
    )
}

export const useUserMutation = () => {
    const queryClient = useQueryClient()

    return useMutation(
        ({
            userId,
            userName,
            companyId,
            role,
            bransch,
            hasProject,
        }: {
            userId: string
            userName: string
            companyId: string
            role: string
            bransch: string
            hasProject: boolean | null
        }) =>
            requests.createUser(
                userId,
                userName,
                companyId,
                role,
                bransch,
                hasProject
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user'])
            },
        }
    )
}

const requests = {
    getUser: async (userId: string): Promise<UserDoc | undefined> => {
        const docSnap = await getDoc(doc(db, COLLECTION, userId))
        return docSnap.exists() ? (docSnap.data() as UserDoc) : undefined
    },
    createUser: async (
        userId: string,
        userName: string,
        companyId: string,
        role: string,
        bransch: string,
        hasProject: boolean | null
    ): Promise<void> => {
        await setDoc(doc(db, COLLECTION, userId), {
            name: userName,
            companyId: companyId,
            signUpDetails: {
                role,
                bransch,
                hasProject,
            },
        } as UserDoc)
    },
}

export default requests
