import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material'

interface TextFieldTagsProps {
    values: string[]
    onTagsChange: (values: string[]) => void
}

export default function TextFieldTags({
    values,
    onTagsChange,
    ...props
}: TextFieldProps & TextFieldTagsProps) {
    return (
        <Autocomplete
            multiple
            options={[]}
            freeSolo
            limitTags={2}
            value={values}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField variant="filled" {...params} {...props} />
            )}
            fullWidth
            onChange={(_e, v) => {
                onTagsChange(v)
            }}
        />
    )
}
