import {
    Box,
    Button,
    Chip,
    Hidden,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import ModalRight from '../../components/modalRight/ModalRight'
import EditContract from './EditProject'
import { ModalCentered } from '../../components'
import CreateProject from './CreateProject'
import {
    CompanyProjectDoc,
    useCompanyProjects,
} from '../../services/companyProjectService'
import ContentLayout from '../../components/contentLayout/ContentLayout'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { getProjectStatus } from './utils/getProjectStatus'
import { useCompanyClients } from '../../services/companyClientService'

export default function Projects() {
    const [add, setAdd] = useState<boolean>(false)
    const [open, setOpen] = useState<CompanyProjectDoc | null>(null)
    const projects = useCompanyProjects()
    const clients = useCompanyClients()
    const isBelowMdScreen = useMediaQuery('(max-width:850px)')

    function formatDate(date: Date | undefined): string {
        if (!date) return '-'
        const year = date.getFullYear()
        const month = ('0' + (date.getMonth() + 1)).slice(-2) // Add 1 because months are 0-indexed
        const day = ('0' + date.getDate()).slice(-2)

        return `${year}-${month}-${day}`
    }

    return (
        <ContentLayout
            title="Uppdrag"
            actionBar={
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setAdd(true)
                        }}
                    >
                        Skapa uppdrag
                    </Button>
                </Box>
            }
        >
            <Box>
                <ModalRight
                    open={open != null}
                    onClose={() => {
                        setOpen(null)
                    }}
                >
                    <EditContract
                        values={open as CompanyProjectDoc}
                        onEdited={() => {
                            setOpen(null)
                        }}
                    />
                </ModalRight>
                <ModalCentered open={add} onClose={() => setAdd(false)}>
                    <CreateProject
                        onCreated={() => {
                            setOpen(null)
                        }}
                    />
                </ModalCentered>
                <Paper>
                    {isBelowMdScreen ? (
                        <List>
                            {(projects.data || []).map(
                                (project: CompanyProjectDoc) => (
                                    <ListItem key={project.id}>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontWeight: 800,
                                                display: 'block',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '100%',
                                            }}
                                            primary={project.title}
                                            secondary={
                                                getProjectStatus(
                                                    project.startDate
                                                        ? new Date(
                                                              project.startDate
                                                          )
                                                        : undefined,
                                                    project.endDate
                                                        ? new Date(
                                                              project.endDate
                                                          )
                                                        : undefined
                                                ).text +
                                                ' - ' +
                                                (clients.data || []).find(
                                                    (client) =>
                                                        client.id ===
                                                        project.clientId
                                                )?.name
                                            }
                                        />
                                        <ListItemSecondaryAction
                                            onClick={() => {
                                                setOpen(project)
                                            }}
                                        >
                                            <EditOutlinedIcon />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            )}
                        </List>
                    ) : (
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Uppdragstitel</TableCell>
                                        <TableCell align="right">
                                            Klient
                                        </TableCell>
                                        <Hidden mdDown>
                                            <TableCell align="center">
                                                Startdatum
                                            </TableCell>
                                            <TableCell align="center">
                                                Slutdatum
                                            </TableCell>
                                        </Hidden>
                                        <TableCell align="center">
                                            Status
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(projects.data || []).map(
                                        (project: CompanyProjectDoc) => (
                                            <TableRow
                                                key={project.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {project.title}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        (
                                                            clients.data || []
                                                        ).find(
                                                            (client) =>
                                                                client.id ===
                                                                project.clientId
                                                        )?.name
                                                    }
                                                </TableCell>
                                                <Hidden mdDown>
                                                    <TableCell align="center">
                                                        {formatDate(
                                                            project.startDate
                                                                ? new Date(
                                                                      project.startDate
                                                                  )
                                                                : undefined
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {formatDate(
                                                            project.endDate
                                                                ? new Date(
                                                                      project.endDate
                                                                  )
                                                                : undefined
                                                        )}
                                                    </TableCell>
                                                </Hidden>
                                                <TableCell align="center">
                                                    <Chip
                                                        variant="outlined"
                                                        color={
                                                            getProjectStatus(
                                                                project.startDate
                                                                    ? new Date(
                                                                          project.startDate
                                                                      )
                                                                    : undefined,
                                                                project.endDate
                                                                    ? new Date(
                                                                          project.endDate
                                                                      )
                                                                    : undefined
                                                            ).color
                                                        }
                                                        label={
                                                            getProjectStatus(
                                                                project.startDate
                                                                    ? new Date(
                                                                          project.startDate
                                                                      )
                                                                    : undefined,
                                                                project.endDate
                                                                    ? new Date(
                                                                          project.endDate
                                                                      )
                                                                    : undefined
                                                            ).text
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Stack
                                                        spacing={1}
                                                        direction="row"
                                                        justifyContent="flex-end"
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                setOpen(project)
                                                            }}
                                                            size="small"
                                                            variant="contained"
                                                            startIcon={
                                                                <EditOutlinedIcon />
                                                            }
                                                        >
                                                            Editera
                                                        </Button>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {(projects.data || []).length <= 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 4,
                            }}
                        >
                            <Typography variant="body1">
                                Du har inte lagt till några uppdrag ännu.
                            </Typography>
                        </Box>
                    )}
                </Paper>
            </Box>
        </ContentLayout>
    )
}
