import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useEffect, useState } from 'react'
import {
    useCompanyClients,
    useCreateCompanyClient,
} from '../../services/companyClientService'

interface Client {
    id?: string
    name: string
    inputValue?: string
}

const filter = createFilterOptions<Client>()

interface ClientFieldProps {
    defaultClientId: string
    onChange: (klientId: string) => void
}

export default function ClientField({
    defaultClientId,
    onChange,
}: ClientFieldProps) {
    const createClient = useCreateCompanyClient()
    const clients = useCompanyClients()

    return (
        <Autocomplete
            onChange={async (event, newValue) => {
                if (typeof newValue === 'string') {
                    const doc = await createClient.mutateAsync({
                        name: newValue,
                    })
                    onChange(doc.id)
                } else if (newValue && newValue.inputValue) {
                    const doc = await createClient.mutateAsync({
                        name: newValue.inputValue,
                    })
                    onChange(doc.id)
                } else {
                    if (newValue?.id) {
                        onChange(newValue.id)
                    }
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const { inputValue } = params
                const isExisting = options.some(
                    (option) => inputValue === option.name
                )
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `Lägg till "${inputValue}"`,
                    })
                }

                return filtered
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={(clients.data || []) as Client[]}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option
                }
                if (option.inputValue) {
                    return option.inputValue
                }
                return option.name
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            defaultValue={
                (clients.data || []).find((client: Client) => {
                    return client.id === defaultClientId
                }) as Client
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Klient"
                    variant="filled"
                    required
                    helperText="Kunden som ditt företag kommer fakturera"
                    fullWidth
                />
            )}
        />
    )
}
