import { MatchRank } from '../types'

export default function getMatchRank(
    value: number,
    min: number,
    max: number
): MatchRank {
    const range = max - min
    const lowerThird = min + range / 3
    const upperThird = min + (2 * range) / 3

    if (value <= lowerThird) {
        return 'Lågt'
    } else if (value <= upperThird) {
        return 'Mellan'
    } else {
        return 'Högt'
    }
}
