import {
    Alert,
    Box,
    Button,
    Grid,
    Hidden,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import useAuth from '../../hooks/UseAuth'

export default function Signin() {
    const { signIn } = useAuth()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    // Eventhandler for submitting
    const handleSubmit = async (event: any) => {
        event.preventDefault()
        await signIn(email, password)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box sx={{ maxWidth: 400, textAlign: 'center', px: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Typography sx={{ mb: 4 }} variant="h1">
                        Logga in
                    </Typography>
                    <TextField
                        type="email"
                        label="E-post"
                        variant="filled"
                        fullWidth
                        margin="normal"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Lösenord"
                        type="password"
                        variant="filled"
                        fullWidth
                        margin="normal"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        sx={{ mt: 4 }}
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        Logga in
                    </Button>
                    <Box sx={{ mt: 4 }}>
                        <Link href="/reset-password">Återställ lösenord</Link>
                    </Box>
                    <Box>-</Box>
                    <Link href="https://konsio.se">
                        Tillbaka till startsida
                    </Link>
                </form>
            </Box>
            <Hidden mdDown>
                <Box
                    sx={{
                        width: '500px',
                        position: 'absolute',
                        bottom: '-80px',
                        right: '-80px',
                    }}
                    component="img"
                    src="/logos.png"
                />
            </Hidden>
        </Box>
    )
}
