import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { connectAuthEmulator, getAuth } from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBsjP7X0DqaMtDL44qAzs882vM1o6CVLQM',
    authDomain: 'sheldon-a4167.firebaseapp.com',
    projectId: 'sheldon-a4167',
    storageBucket: 'sheldon-a4167.appspot.com',
    messagingSenderId: '491210588672',
    appId: '1:491210588672:web:e3c5327cde15b5bedfcee5',
    measurementId: 'G-PNRVXK5BL4',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth()

if (process.env.NODE_ENV != 'production') {
    connectFirestoreEmulator(db, 'localhost', 8080)
    connectAuthEmulator(auth, 'http://localhost:9099')
}

export { db, auth }
export default app
