import {
    Box,
    Button,
    Chip,
    Collapse,
    Hidden,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Fragment, useState } from 'react'
import AddTaskIcon from '@mui/icons-material/AddTask'
import ContentLayout from '../../components/contentLayout/ContentLayout'
import { ModalCentered } from '../../components'
import CreateProposal from './CreateProposal'
import {
    CompanyProposalDoc,
    CompanyProposalEditables,
    ProposalStatus,
    useCompanyProposals,
    useUpdateCompanyProposal,
} from '../../services/companyProposalService'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ModalRight from '../../components/modalRight/ModalRight'
import EditProposal from './EditProposal'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { ProposalStatusTableCell } from './types'
import CreateProject from '../projects/CreateProject'
import { useCompanyClients } from '../../services/companyClientService'
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material'

export default function Proposals() {
    const companyProposals = useCompanyProposals()
    const companyProposalMutation = useUpdateCompanyProposal()
    const [open, setOpen] = useState<CompanyProposalDoc | null>(null)
    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [addContract, setAddContract] = useState<CompanyProposalDoc | null>(
        null
    )
    const clients = useCompanyClients()
    const isBelowMdScreen = useMediaQuery('(max-width:850px)')
    const [openId, setOpenId] = useState<string | null>(null)

    const getStatus = (status: ProposalStatus): ProposalStatusTableCell => {
        switch (status) {
            case ProposalStatus.APPROVED:
                return {
                    text: 'Accepterat',
                    color: 'success',
                }
            case ProposalStatus.DISMISSED:
                return {
                    text: 'Avfärdat',
                    color: 'error',
                }
            default:
                return {
                    text: 'Skickat',
                    color: 'info',
                }
        }
    }

    return (
        <ContentLayout
            title="Anbud"
            actionBar={
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setShowAddModal(true)
                        }}
                    >
                        Skapa anbud
                    </Button>
                </Box>
            }
        >
            <ModalRight
                open={open != null}
                onClose={() => {
                    setOpen(null)
                }}
            >
                <EditProposal
                    disabled={open?.status != ProposalStatus.APPLIED}
                    defaultValues={open as CompanyProposalDoc}
                    onEdited={() => {
                        setOpen(null)
                    }}
                    onDelete={() => {
                        setOpen(null)
                    }}
                />
            </ModalRight>
            <ModalCentered
                open={showAddModal}
                onClose={() => setShowAddModal(false)}
            >
                <CreateProposal
                    onSubmitted={() => {
                        setShowAddModal(false)
                    }}
                />
            </ModalCentered>
            <ModalCentered
                open={addContract != null}
                onClose={() => setAddContract(null)}
            >
                <CreateProject
                    defaultValues={addContract || undefined}
                    onCreated={async () => {
                        await companyProposalMutation.mutateAsync({
                            ...(addContract as CompanyProposalDoc),
                            status: ProposalStatus.APPROVED,
                        })
                        setAddContract(null)
                    }}
                />
            </ModalCentered>
            {isBelowMdScreen ? (
                <Box>
                    {(companyProposals.data || []).map(
                        (proposal: CompanyProposalDoc) => (
                            <Fragment>
                                <ListItemButton
                                    onClick={() => {
                                        setOpenId(
                                            openId === proposal.id
                                                ? null
                                                : proposal.id || null
                                        )
                                    }}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontWeight: 800,
                                            display: 'block',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%',
                                        }}
                                        primary={proposal.title}
                                        secondary={
                                            getStatus(proposal.status).text +
                                            ' - ' +
                                            (clients.data || []).find(
                                                (client) =>
                                                    client.id ===
                                                    proposal.clientId
                                            )?.name
                                        }
                                    />
                                    {openId != null ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={openId === proposal.id}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <ListItemButton
                                            onClick={() => {
                                                setAddContract(proposal)
                                            }}
                                            sx={{ pl: 4 }}
                                            disabled={
                                                proposal.status !=
                                                ProposalStatus.APPLIED
                                            }
                                        >
                                            <ListItemIcon>
                                                <AddTaskIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Accepterat" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() => {
                                                setOpen(proposal)
                                            }}
                                            sx={{ pl: 4 }}
                                        >
                                            <ListItemIcon>
                                                <EditOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Editera" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() =>
                                                companyProposalMutation.mutate({
                                                    ...proposal,
                                                    status: ProposalStatus.DISMISSED,
                                                })
                                            }
                                            sx={{ pl: 4 }}
                                            disabled={
                                                proposal.status !=
                                                ProposalStatus.APPLIED
                                            }
                                        >
                                            <ListItemIcon>
                                                <CancelOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Avfärda anbud" />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </Fragment>
                        )
                    )}
                </Box>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Uppdragstitel</TableCell>
                                <TableCell align="right">Klient</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(companyProposals.data || []).map(
                                (proposal: CompanyProposalDoc) => (
                                    <TableRow
                                        key={1}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {proposal.title}
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                (clients.data || []).find(
                                                    (client) =>
                                                        client.id ===
                                                        proposal.clientId
                                                )?.name
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                variant="outlined"
                                                color={
                                                    getStatus(proposal.status)
                                                        .color
                                                }
                                                label={
                                                    getStatus(proposal.status)
                                                        .text
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                spacing={1}
                                                direction="row"
                                                justifyContent="flex-end"
                                            >
                                                <Button
                                                    onClick={() => {
                                                        setAddContract(proposal)
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    startIcon={<AddTaskIcon />}
                                                    disabled={
                                                        proposal.status !=
                                                        ProposalStatus.APPLIED
                                                    }
                                                >
                                                    Accepterat
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setOpen(proposal)
                                                    }}
                                                    size="small"
                                                    variant="contained"
                                                    startIcon={
                                                        <EditOutlinedIcon />
                                                    }
                                                >
                                                    Editera
                                                </Button>
                                                <Tooltip title="Avfärda anbud">
                                                    <IconButton
                                                        disabled={
                                                            proposal.status !=
                                                            ProposalStatus.APPLIED
                                                        }
                                                        onClick={() =>
                                                            companyProposalMutation.mutate(
                                                                {
                                                                    ...proposal,
                                                                    status: ProposalStatus.DISMISSED,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <CancelOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {(companyProposals.data || []).length <= 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 4,
                    }}
                >
                    <Typography variant="body1">
                        Det verkar som att det inte finns några anbud just nu.
                    </Typography>
                </Box>
            )}
        </ContentLayout>
    )
}
