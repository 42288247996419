import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import {
    CompanyProjectDoc,
    useUpdateCompanyProject,
} from '../../services/companyProjectService'
import FormProject from '../../components/formProject/FormProject'

interface EditProjectProps {
    values: CompanyProjectDoc
    onEdited: () => void
}

export default function EditProject({ values, onEdited }: EditProjectProps) {
    const updateCompanyProject = useUpdateCompanyProject()
    const [form, setForm] = useState<CompanyProjectDoc>(values)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        } as CompanyProjectDoc)
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        updateCompanyProject.mutateAsync(form)
        onEdited && onEdited()
    }

    return (
        <Box
            sx={{
                '> *': {
                    mb: 4,
                },
            }}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <Box>
                <Typography variant="h3" component="h2">
                    Uppdatera uppdrag
                </Typography>
            </Box>
            <FormProject
                values={form}
                onChange={handleChange}
                onClientChange={(clientId) => {
                    setForm({ ...form, clientId })
                }}
            />
            <Button type="submit" variant="contained" size="large" fullWidth>
                Spara
            </Button>
        </Box>
    )
}
