import { CompanyTimesheetUserProjects } from '../../../services/companyTimesheetUserService'

export function getWeeklyHours(
    timesheet: CompanyTimesheetUserProjects,
    weekNumber: number
): number | null {
    let totalHours = 0
    let reported = false

    for (const contractId in timesheet) {
        const weeklyTimesheet = timesheet[contractId]

        for (const key in weeklyTimesheet) {
            const dayInfo = weeklyTimesheet[key]

            if (dayInfo.weekNumber === weekNumber && dayInfo.hours) {
                reported = true
                totalHours += dayInfo.hours
            }
        }
    }

    if (reported) {
        return totalHours
    }

    return null
}
