import useAuth from '../../hooks/UseAuth'
import { Box, Button } from '@mui/material'
import FormProposal from '../../components/formProposal/FormProposal'
import {
    CompanyProposalEditables,
    RateType,
    useCreateCompanyProposal,
} from '../../services/companyProposalService'
import { useState } from 'react'

const defaultValues = {
    title: '',
    clientId: '',
    rateType: RateType.HOURLY,
} as CompanyProposalEditables

export default function CreateProposalExtension() {
    const { user } = useAuth()
    const proposalMutation = useCreateCompanyProposal()
    const [form, setForm] = useState<CompanyProposalEditables>(defaultValues)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === undefined) return
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        } as CompanyProposalEditables)
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        const response = await proposalMutation.mutateAsync(form)
        setForm(defaultValues)
    }

    if (!user) {
        return (
            <Button
                href="http://localhost:3000"
                target="__blank"
                variant="contained"
                size="large"
                fullWidth
            >
                Logga in
            </Button>
        )
    }

    return (
        <Box
            sx={{
                '> *': {
                    mb: 4,
                },
            }}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <FormProposal
                values={form}
                onChange={handleChange}
                onClientChange={(clientId) => {
                    setForm({ ...form, clientId })
                }}
            />
            <Button type="submit" variant="contained" size="large" fullWidth>
                Spara
            </Button>
        </Box>
    )
}
