import { Box, Button, Divider, Typography } from '@mui/material'
import React, { useState } from 'react'
import FormProposal from '../../components/formProposal/FormProposal'
import {
    CompanyProposalDoc,
    useDeleteCompanyProposal,
    useUpdateCompanyProposal,
} from '../../services/companyProposalService'

interface EditProposalProps {
    defaultValues: CompanyProposalDoc
    disabled?: boolean
    onEdited: () => void
    onDelete: () => void
}

export default function EditProposal({
    defaultValues,
    disabled,
    onEdited,
    onDelete,
}: EditProposalProps) {
    const deleteCompanyProposal = useDeleteCompanyProposal()
    const companyProposal = useUpdateCompanyProposal()
    const [form, setForm] = useState<CompanyProposalDoc>(defaultValues)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        } as CompanyProposalDoc)
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        companyProposal.mutateAsync(form)
        onEdited()
    }

    return (
        <Box
            sx={{
                '> *': {
                    mb: 4,
                },
            }}
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <Box>
                <Typography variant="h3" component="h2">
                    Uppdatera anbud
                </Typography>
            </Box>
            <Box>
                <FormProposal
                    values={form}
                    onChange={handleChange}
                    onClientChange={(clientId) => {
                        setForm({ ...form, clientId })
                    }}
                />
            </Box>
            <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                disabled={disabled}
            >
                Spara
            </Button>
            <Box sx={{ mt: 4 }}>
                <Divider />
            </Box>
            <Button
                onClick={async () => {
                    await deleteCompanyProposal.mutate(defaultValues.id || '')
                    onDelete()
                }}
                color="error"
                variant="contained"
                size="large"
                fullWidth
            >
                Ta bort anbud
            </Button>
        </Box>
    )
}
