import {
    DocumentData,
    DocumentReference,
    QuerySnapshot,
    Timestamp,
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    setDoc,
    updateDoc,
} from 'firebase/firestore'
import { db } from '../libs/firebase'
import { COLLECTION } from './companyService'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useSnackbar from '../hooks/UseSnackbar'
import { useUserData } from './userService'

const SUB_COLLECTION = 'proposals'

export enum ProposalStatus {
    APPLIED = 'sent',
    DISMISSED = 'dismissed',
    APPROVED = 'approved',
}

export enum RateType {
    HOURLY = 'hourly',
    FLAT = 'flat',
}

export interface CompanyProposalSysVars {
    status: ProposalStatus
    createdAt: Timestamp
}

export interface CompanyProposalEditables {
    title: string
    description: string
    clientId: string
    customer?: string
    link?: string
    startDate?: string
    endDate?: string
    rateType: RateType
    price?: number
}

export interface CompanyProposalDoc
    extends CompanyProposalSysVars,
        CompanyProposalEditables {
    id?: string
}

export const useCreateCompanyProposal = () => {
    const snackbar = useSnackbar()
    const user = useUserData()
    const queryClient = useQueryClient()

    return useMutation(
        (proposal: CompanyProposalEditables) =>
            requests.createProposal(user.data?.companyId || '', proposal),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SUB_COLLECTION])
                snackbar.openSnackbar('Anbud sparades!')
            },
            onError: () => {
                snackbar.openSnackbar(
                    'Det uppstod ett fel. Anbudet har inte sparats'
                )
            },
        }
    )
}

export const useCompanyProposals = () => {
    const user = useUserData()

    return useQuery(
        [SUB_COLLECTION],
        async () => await requests.getProposals(user.data?.companyId || ''),
        {
            enabled: user.data?.companyId != undefined,
            cacheTime: Infinity,
        }
    )
}

export const useUpdateCompanyProposal = () => {
    const snackbar = useSnackbar()
    const user = useUserData()
    const queryClient = useQueryClient()

    return useMutation(
        (proposal: CompanyProposalDoc) =>
            requests.updateProposal(
                user.data?.companyId || '',
                proposal.id || '',
                proposal
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SUB_COLLECTION])
                snackbar.openSnackbar('Anbud updaterades!')
            },
            onError: () => {
                snackbar.openSnackbar(
                    'Det uppstod ett fel. Anbudet har inte uppdaterats'
                )
            },
        }
    )
}

export const useDeleteCompanyProposal = () => {
    const snackbar = useSnackbar()
    const user = useUserData()
    const queryClient = useQueryClient()

    return useMutation(
        (proposalId: string) =>
            requests.deleteProposal(user.data?.companyId || '', proposalId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SUB_COLLECTION])
                snackbar.openSnackbar('Anbud togs bort!')
            },
            onError: () => {
                snackbar.openSnackbar(
                    'Det uppstod ett fel. Anbudet har inte tagits bort'
                )
            },
        }
    )
}

const requests = {
    getProposals: async (companyId: string): Promise<CompanyProposalDoc[]> => {
        const docs = await getDocs(
            collection(db, COLLECTION, companyId, SUB_COLLECTION)
        )
        return docs.docs.map(
            (doc) =>
                ({
                    id: doc.id,
                    ...doc.data(),
                }) as CompanyProposalDoc
        )
    },
    createProposal: async (
        companyId: string,
        proposal: CompanyProposalEditables
    ): Promise<DocumentReference<DocumentData>> => {
        if (companyId.length <= 0) throw Error('Inget företagsid')
        const doc: CompanyProposalDoc = {
            status: ProposalStatus.APPLIED,
            createdAt: Timestamp.fromDate(new Date()),
            ...proposal,
        }
        return await addDoc(
            collection(db, COLLECTION, companyId, SUB_COLLECTION),
            doc
        )
    },
    updateProposal: async (
        companyId: string,
        proposalId: string,
        proposal: CompanyProposalDoc
    ) => {
        if (proposalId.length <= 0) throw Error('Inget anbudsid')
        if (companyId.length <= 0) throw Error('Inget företagsid')
        delete proposal.id
        return await setDoc(
            doc(db, COLLECTION, companyId, SUB_COLLECTION, proposalId),
            proposal
        )
    },
    deleteProposal: async (companyId: string, proposalId: string) => {
        return await deleteDoc(
            doc(db, COLLECTION, companyId, SUB_COLLECTION, proposalId)
        )
    },
}

export default requests
