import {
    DocumentData,
    DocumentReference,
    addDoc,
    collection,
    doc,
    getDoc,
} from 'firebase/firestore'
import { db } from '../libs/firebase'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useUserData } from './userService'

export const COLLECTION = 'companies'

export type CompanyDoc = {
    name: string
}

export const useCompanyData = (): UseQueryResult<
    CompanyDoc | null | undefined
> => {
    const user = useUserData()
    return useQuery(
        ['company'],
        async () => await requests.getCompany(user.data?.companyId || ''),
        {
            enabled: user.data?.companyId != undefined,
            cacheTime: Infinity,
        }
    )
}

const requests = {
    getCompany: async (
        companyId: string
    ): Promise<CompanyDoc | null | undefined> => {
        const docSnap = await getDoc(doc(db, COLLECTION, companyId))
        return docSnap.exists()
            ? (docSnap.data() as CompanyDoc | null)
            : undefined
    },
    createCompany: async (): Promise<DocumentReference<DocumentData>> => {
        return await addDoc(collection(db, COLLECTION), {})
    },
}

export default requests
