import { Fragment } from 'react'
import FormProposal from '../formProposal/FormProposal'
import {
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material'
import {
    BillingSchedule,
    CompanyProjectEditables,
} from '../../services/companyProjectService'

interface FormProjectProps {
    values: CompanyProjectEditables
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onClientChange: (clientId: string) => void
}

export default function FormProject({
    values,
    onChange,
    onClientChange,
}: FormProjectProps) {
    return (
        <Fragment>
            <FormProposal
                values={values}
                onChange={onChange}
                onClientChange={onClientChange}
            />
            <Stack direction="row" spacing={2}>
                <TextField
                    type="number"
                    name="estimatedWorkload"
                    label="Beräknad arbetsmängd"
                    variant="filled"
                    fullWidth
                    onChange={onChange}
                    value={values.estimatedWorkload}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                />
                <FormControl sx={{ width: 200 }} variant="filled">
                    <InputLabel id="inputLabel-type">
                        Faktureringsschema
                    </InputLabel>
                    <Select
                        name="rateType"
                        labelId="inputLabel-type"
                        value={values.billingSchedule}
                        onChange={(e) => {
                            const inputEvent =
                                e as unknown as React.ChangeEvent<HTMLInputElement>
                            onChange(inputEvent)
                        }}
                        autoWidth
                        label="Faktureringsschema"
                    >
                        <MenuItem value={BillingSchedule.MONTHLY}>
                            per månad
                        </MenuItem>
                        <MenuItem value={BillingSchedule.WEEKLY}>
                            per vecka
                        </MenuItem>
                        <MenuItem value={BillingSchedule.ONCE}>
                            Engångsbetalning
                        </MenuItem>
                    </Select>
                </FormControl>
            </Stack>
        </Fragment>
    )
}
