import { DayInfo, Weekday } from '../../services/companyTimesheetUserService'

export const days: Weekday[] = [
    'måndag',
    'tisdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lördag',
    'söndag',
]

export type MonthlyTimesheetBase = {
    weekNumbers: number[]
    weekDates: Record<number, DayInfo[]>
}
