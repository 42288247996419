import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Container,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/UseAuth'
import { grey } from '@mui/material/colors'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { ArchitectureOutlined } from '@mui/icons-material'
import { useUserData } from '../../services/userService'
import { useCompanyData } from '../../services/companyService'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined'
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined'
import ModalCentered from '../modalCentered/ModalCentered'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

export default function LayoutAuth() {
    const theme = useTheme()
    const navigate = useNavigate()
    const { signOut } = useAuth()
    const user = useUserData()
    const company = useCompanyData()
    const matches = useMediaQuery('(max-width:850px)')
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
    const [featureRequestDisplay, setFeatureRequestDisplay] =
        useState<boolean>(false)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    if (matches) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <ModalCentered
                    open={featureRequestDisplay}
                    onClose={() => setFeatureRequestDisplay(false)}
                >
                    <>
                        <script
                            type="text/javascript"
                            src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
                        ></script>
                        <style type="text/css" media="screen, projection">
                            @import
                            url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
                        </style>
                        <iframe
                            title="Feedback Form"
                            className="freshwidget-embedded-form"
                            id="freshwidget-embedded-form"
                            src="https://konsio.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feature+request&submitTitle=Skicka&searchArea=no"
                            scrolling="no"
                            height="500px"
                            width="100%"
                            frameBorder="0"
                        ></iframe>
                    </>
                </ModalCentered>
                <AppBar color="transparent" position="static">
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    component="img"
                                    src="/logo.png"
                                    width={100}
                                />
                                <Box
                                    sx={{
                                        ml: 1,
                                        background:
                                            theme.palette.secondary.main,
                                        px: 1,
                                        py: 0.5,
                                        borderRadius: 8,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '10px',
                                            fontWeight: 800,
                                        }}
                                    >
                                        BETA
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuRoundedIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            onClick={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuList sx={{ width: 200 }}>
                                <MenuItem onClick={() => navigate('/leads')}>
                                    <ListItemIcon>
                                        <LeaderboardOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Leads</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => navigate('/proposals')}
                                >
                                    <ListItemIcon>
                                        <AddBusinessOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Anbud</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate('/projects')
                                    }}
                                >
                                    <ListItemIcon>
                                        <AccountTreeOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Uppdrag" />
                                </MenuItem>
                                <MenuItem
                                    onClick={() => navigate('/timesheet')}
                                >
                                    <ListItemIcon>
                                        <AccessTimeOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Tidsrapport</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate('/invoices')
                                    }}
                                >
                                    <ListItemIcon>
                                        <ReceiptOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Faktureringsöversikt" />
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    onClick={() => {
                                        setFeatureRequestDisplay(true)
                                    }}
                                >
                                    <ListItemIcon>
                                        <ArchitectureOutlined fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Feature request</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to="https://konsio.freshdesk.com/"
                                    target="_blank"
                                >
                                    <ListItemIcon>
                                        <EmailOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Kontakta oss" />
                                </MenuItem>
                                <MenuItem onClick={signOut}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Logga ut</ListItemText>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Divider />
                <Box sx={{ mt: 4, p: 2 }}>
                    <Outlet />
                </Box>
            </Box>
        )
    }
    return (
        <Fragment>
            <ModalCentered
                open={featureRequestDisplay}
                onClose={() => setFeatureRequestDisplay(false)}
            >
                <>
                    <script
                        type="text/javascript"
                        src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
                    ></script>
                    <style type="text/css" media="screen, projection">
                        @import
                        url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
                    </style>
                    <iframe
                        title="Feedback Form"
                        className="freshwidget-embedded-form"
                        id="freshwidget-embedded-form"
                        src="https://konsio.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feature+request&submitTitle=Skicka&searchArea=no"
                        scrolling="no"
                        height="500px"
                        width="100%"
                        frameBorder="0"
                    ></iframe>
                </>
            </ModalCentered>
            <Container maxWidth={false}>
                <Grid container spacing={4} sx={{ pt: 4, height: '100vh' }}>
                    <Grid
                        item
                        xs={3}
                        xl={2}
                        sx={{
                            background: grey[200],
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            borderRight: '1px solid',
                            borderColor: grey[300],
                        }}
                    >
                        <Box>
                            <Badge
                                color="secondary"
                                badgeContent={
                                    <Typography
                                        sx={{
                                            fontSize: '10px',
                                            fontWeight: 800,
                                        }}
                                    >
                                        BETA
                                    </Typography>
                                }
                            >
                                <Box
                                    component="img"
                                    src="/logo.png"
                                    width={150}
                                />
                            </Badge>
                            <Box sx={{ mt: 4, mr: 4 }}>
                                <Box>
                                    <ListItem
                                        component="div"
                                        sx={{
                                            border: '1px solid',
                                            borderColor: grey[400],
                                            borderRadius: 2,
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    background: (theme) =>
                                                        theme.palette.secondary
                                                            .dark,
                                                }}
                                            >
                                                {user.data?.name.charAt(0)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.data?.name}
                                            secondary={
                                                company.data?.name ? (
                                                    <Typography variant="body2">
                                                        {company.data?.name}
                                                    </Typography>
                                                ) : (
                                                    <Link
                                                        onClick={signOut}
                                                        style={{
                                                            color: theme.palette
                                                                .grey[700],
                                                            fontWeight: 'bold',
                                                            textDecoration:
                                                                'none',
                                                        }}
                                                        to={''}
                                                    >
                                                        Logga ut
                                                    </Link>
                                                )
                                            }
                                        />
                                    </ListItem>
                                </Box>
                                <Box
                                    sx={{
                                        mt: 4,
                                        '> a': {
                                            display: 'block',
                                            mb: 2,
                                            textDecoration: 'none',
                                            color: grey[800],
                                            letterSpacing: 1,
                                            fontFamily: (theme) =>
                                                theme.typography.h1.fontFamily,
                                        },
                                    }}
                                >
                                    <List>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate('/leads')
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <LeaderboardOutlinedIcon />
                                            </ListItemAvatar>
                                            <ListItemText primary="Leads" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate('/proposals')
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <AddBusinessOutlinedIcon />
                                            </ListItemAvatar>
                                            <ListItemText primary="Anbud" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate('/projects')
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <AccountTreeOutlinedIcon />
                                            </ListItemAvatar>
                                            <ListItemText primary="Uppdrag" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate('/timesheet')
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <AccessTimeOutlinedIcon />
                                            </ListItemAvatar>
                                            <ListItemText primary="Tidrapport" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate('/invoices')
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <ReceiptOutlinedIcon />
                                            </ListItemAvatar>
                                            <ListItemText primary="Faktureringsöversikt" />
                                        </ListItemButton>
                                    </List>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 4, mr: 4 }}>
                            <List>
                                <ListItemButton
                                    onClick={() => {
                                        setFeatureRequestDisplay(true)
                                    }}
                                    sx={{
                                        background:
                                            theme.palette.secondary.light,
                                        p: 2,
                                        borderRadius: 2,
                                        mb: 4,
                                        ':hover': {
                                            background:
                                                theme.palette.secondary.main,
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <ArchitectureOutlined />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Feature request"
                                        secondary="Hjälp oss, hjälpa dig. Vilken funktionalitet vill du se härnäst?"
                                        primaryTypographyProps={{
                                            fontWeight: 600,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 11,
                                        }}
                                    />
                                </ListItemButton>
                                <ListItemButton
                                    href="https://konsio.freshdesk.com/"
                                    target="_blank"
                                >
                                    <ListItemAvatar>
                                        <EmailOutlinedIcon />
                                    </ListItemAvatar>
                                    <ListItemText primary="Kontakta oss" />
                                </ListItemButton>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={9} xl={10}>
                        <Box sx={{ height: '92vh' }}>
                            <Outlet />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}
