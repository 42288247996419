import { Close } from '@mui/icons-material'
import {
    Box,
    IconButton,
    Modal,
    ModalProps,
    Paper,
    useMediaQuery,
} from '@mui/material'
import ModalCentered from '../modalCentered/ModalCentered'

export default function ModalRight(props: ModalProps) {
    const isBelowMdScreen = useMediaQuery('(max-width:850px)')

    if (isBelowMdScreen) {
        return (
            <ModalCentered
                {...props}
                onClick={() => {
                    props.onClose && props.onClose({}, 'backdropClick')
                }}
            >
                {props.children}
            </ModalCentered>
        )
    }
    return (
        <Modal {...props}>
            <Paper
                sx={{
                    minWidth: 400,
                    position: 'absolute',
                    right: 0,
                    height: '100vh',
                    p: 4,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '8px',
                        p: 2,
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.onClose && props.onClose({}, 'backdropClick')
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
                {props.children}
            </Paper>
        </Modal>
    )
}
