import { MonthlyTimesheetBase } from '../types'
import { getAllDatesInMonth } from './getAllDaysInMonth'
import { getGroupedWeeks } from './getGroupedWeeks'
import { getUniqueWeekNumbers } from './getUniqueWeekNumbers'
import { splitYearAndMonth } from './splitYearAndMonth'

/**
 * Get base structure for a monthly timesheet
 * @param monthYear - Month and year in format YYYY-MM
 */
export function getMonthlyTimesheet(monthYear: string): MonthlyTimesheetBase {
    const selectedMonthYear = splitYearAndMonth(monthYear)
    const dates = getAllDatesInMonth(
        selectedMonthYear.month - 1,
        selectedMonthYear.year
    )
    const weekNumbers = getUniqueWeekNumbers(dates)
    const weekDates = getGroupedWeeks(dates)
    return {
        weekNumbers,
        weekDates,
    }
}
