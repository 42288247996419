import {
    DocumentData,
    DocumentReference,
    Timestamp,
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
} from 'firebase/firestore'
import { db } from '../libs/firebase'
import { COLLECTION } from './companyService'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useSnackbar from '../hooks/UseSnackbar'
import { useUserData } from './userService'
import useAuth from '../hooks/UseAuth'

const SUB_COLLECTION = 'timesheets'

export type Weekday =
    | 'måndag'
    | 'tisdag'
    | 'onsdag'
    | 'torsdag'
    | 'fredag'
    | 'lördag'
    | 'söndag'

export type DayInfo = {
    date: Date
    weekNumber: number
    weekDay: Weekday
    hours?: number
}

export interface CompanyTimesheetUserEditables {
    projects: CompanyTimesheetUserProjects
}

export interface CompanyTimesheetUserProjects {
    [projects: string]: {
        [key: number]: DayInfo
    }
}

export interface CompanyTimesheetUserSysVars {
    finishedAt?: Timestamp
    createdAt: Timestamp
    userId: string
    yearMonth: string
}

export interface CompanyTimesheetUserDoc
    extends CompanyTimesheetUserEditables,
        CompanyTimesheetUserSysVars {
    id?: string
}

export const useCreateCompanyTimesheetUser = () => {
    const snackbar = useSnackbar()
    const userAuth = useAuth()
    const user = useUserData()
    const queryClient = useQueryClient()

    return useMutation(
        ({
            yearMonth,
            timesheet,
        }: {
            yearMonth: string
            timesheet: CompanyTimesheetUserEditables
        }) =>
            requests.createTimesheetUser(
                user.data?.companyId || '',
                yearMonth,
                userAuth.user?.uid || '',
                timesheet
            ),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries([
                    SUB_COLLECTION,
                    'user',
                    variables.yearMonth,
                ])
                snackbar.openSnackbar('Tidrapport sparades!')
            },
            onError: () => {
                snackbar.openSnackbar(
                    'Det uppstod ett fel. Tidrapport har inte sparats'
                )
            },
        }
    )
}

export const useCompanyTimesheetUser = (yearMonth: string) => {
    const userAuth = useAuth()
    const user = useUserData()

    return useQuery(
        [SUB_COLLECTION, 'user', yearMonth],
        async () =>
            await requests.getTimesheetUser(
                user.data?.companyId || '',
                yearMonth,
                userAuth.user?.uid || ''
            ),
        {
            enabled:
                user.data?.companyId != undefined &&
                userAuth.user?.uid != undefined,
            cacheTime: Infinity,
        }
    )
}

const requests = {
    getTimesheetUser: async (
        companyId: string,
        yearMonth: string,
        userId: string
    ): Promise<CompanyTimesheetUserDoc | null> => {
        const docRef = await getDoc(
            doc(
                db,
                COLLECTION,
                companyId,
                SUB_COLLECTION,
                `${yearMonth}-${userId}`
            )
        )
        if (docRef.exists()) {
            return {
                id: docRef.id,
                ...docRef.data(),
            } as CompanyTimesheetUserDoc
        }
        return null
    },
    createTimesheetUser: async (
        companyId: string,
        yearMonth: string,
        userId: string,
        timesheet: CompanyTimesheetUserEditables
    ): Promise<void> => {
        if (companyId.length <= 0) throw Error('Inget företagsid')
        const newDoc: CompanyTimesheetUserDoc = {
            createdAt: Timestamp.fromDate(new Date()),
            userId: userId,
            yearMonth: yearMonth,
            ...timesheet,
        }
        await setDoc(
            doc(
                db,
                COLLECTION,
                companyId,
                SUB_COLLECTION,
                `${yearMonth}-${userId}`
            ),
            newDoc
        )
    },
}

export default requests
