import { Timestamp } from 'firebase/firestore'

type Status = 'Ej påbörjad' | 'Pågående' | 'Avslutad' | 'Okänt'
type ProjectStatusTableCell = {
    text: Status
    color:
        | 'default'
        | 'secondary'
        | 'primary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
}

export function getProjectStatus(
    startDate?: Date,
    endDate?: Date
): ProjectStatusTableCell {
    const now = new Date()
    if (startDate === undefined || endDate === undefined) {
        return {
            text: 'Okänt',
            color: 'default',
        }
    } else if (now < startDate) {
        return {
            text: 'Ej påbörjad',
            color: 'info',
        }
    } else if (now > endDate) {
        return {
            text: 'Avslutad',
            color: 'warning',
        }
    }

    return {
        text: 'Pågående',
        color: 'success',
    }
}
