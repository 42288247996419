import { collection, getDocs, query, where } from 'firebase/firestore'
import { CompanyTimesheetUserDoc } from './companyTimesheetUserService'
import { db } from '../libs/firebase'
import { COLLECTION } from './companyService'
import { useUserData } from './userService'
import { useQuery } from '@tanstack/react-query'

const SUB_COLLECTION = 'timesheets'

export const useCompanyTimesheets = (yearMonth: string) => {
    const user = useUserData()

    return useQuery(
        [SUB_COLLECTION, yearMonth],
        async () =>
            await requests.getTimesheets(user.data?.companyId || '', yearMonth),
        {
            enabled: user.data?.companyId != undefined,
            cacheTime: Infinity,
        }
    )
}

const requests = {
    getTimesheets: async (
        companyId: string,
        yearMonth: string
    ): Promise<CompanyTimesheetUserDoc[]> => {
        const q = query(
            collection(db, COLLECTION, companyId, SUB_COLLECTION),
            where('yearMonth', '==', yearMonth)
        )
        const docsRef = await getDocs(q)
        return docsRef.docs.map(
            (doc) =>
                ({
                    id: doc.id,
                    ...doc.data(),
                }) as CompanyTimesheetUserDoc
        )
    },
}

export default requests
