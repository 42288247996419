import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import ContentLayout from '../../components/contentLayout/ContentLayout'
import { getCurrentYearMonth } from './utils/getCurrentYearMonth'
import { useMemo, useState } from 'react'
import { MonthlyTimesheetBase } from './types'
import { getMonthlyTimesheet } from './utils/getMonthlyTimesheet'
import { getDateTimeFromDates } from './utils/getDateTimeFromDates'
import ModalRight from '../../components/modalRight/ModalRight'
import WeeklyTimesheet from './WeeklyTimesheet'
import { getWeeklyHours } from './utils/getWeeklyHours'
import {
    DayInfo,
    useCompanyTimesheetUser,
} from '../../services/companyTimesheetUserService'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'

export default function Timesheet() {
    const [monthYear, setMonthYear] = useState<string>(getCurrentYearMonth())
    const month = useMemo<MonthlyTimesheetBase>(
        () => getMonthlyTimesheet(monthYear),
        [monthYear]
    )
    const timesheet = useCompanyTimesheetUser(monthYear)
    const [displayDates, setDisplayDates] = useState<DayInfo[] | null>(null)
    const isBelowMdScreen = useMediaQuery('(max-width:850px)')

    return (
        <ContentLayout
            title="Tidrapport"
            actionBar={
                <Box>
                    <TextField
                        type="month"
                        sx={{ maxWidth: isBelowMdScreen ? 130 : null }}
                        inputProps={{
                            max: getCurrentYearMonth(),
                        }}
                        value={monthYear}
                        onChange={(e) => setMonthYear(e.target.value)}
                        size={isBelowMdScreen ? 'small' : 'medium'}
                        required
                    />
                </Box>
            }
        >
            <ModalRight
                open={displayDates != null}
                onClose={() => {
                    setDisplayDates(null)
                }}
            >
                <WeeklyTimesheet
                    dates={displayDates ? displayDates : []}
                    defaultTimesheet={timesheet.data?.projects || {}}
                    onFinished={() => {
                        setDisplayDates(null)
                    }}
                />
            </ModalRight>
            <Box>
                <List>
                    {month.weekNumbers.map((weekNumber: number) => (
                        <ListItem key={weekNumber}>
                            <ListItemAvatar>
                                <Avatar
                                    variant="rounded"
                                    sx={(theme) => ({
                                        background: theme.palette.grey[300],
                                        p: 3,
                                        textAlign: 'center',
                                    })}
                                >
                                    <Box
                                        sx={(theme) => ({
                                            color: theme.palette.grey[600],
                                        })}
                                    >{`v${weekNumber}`}</Box>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ ml: 2 }}
                                primary={`Period: ${getDateTimeFromDates(
                                    month.weekDates[weekNumber]
                                )}`}
                                secondary={`Rapporterad tid: ${
                                    timesheet.data != null
                                        ? getWeeklyHours(
                                              timesheet.data.projects,
                                              weekNumber
                                          ) || 0
                                        : 0
                                } timmar`}
                            />
                            {isBelowMdScreen ? (
                                <ListItemSecondaryAction
                                    onClick={() => {
                                        setDisplayDates(
                                            month.weekDates[weekNumber]
                                        )
                                    }}
                                >
                                    <BorderColorOutlinedIcon />
                                </ListItemSecondaryAction>
                            ) : (
                                <>
                                    {timesheet.data != null &&
                                    getWeeklyHours(
                                        timesheet.data.projects,
                                        weekNumber
                                    ) ? (
                                        <Button
                                            size="large"
                                            variant="outlined"
                                            onClick={() => {
                                                setDisplayDates(
                                                    month.weekDates[weekNumber]
                                                )
                                            }}
                                        >
                                            Uppdatera tid
                                        </Button>
                                    ) : (
                                        <Button
                                            size="large"
                                            onClick={() => {
                                                setDisplayDates(
                                                    month.weekDates[weekNumber]
                                                )
                                            }}
                                        >
                                            Rapportera tid
                                        </Button>
                                    )}
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </ContentLayout>
    )
}
