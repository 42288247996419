import { doc, setDoc } from 'firebase/firestore'
import { db } from '../libs/firebase'
import { COLLECTION } from './companyService'

const SUB_COLLECTION = 'users'

export enum Roles {
    OWNER = 'owner',
}

export type CompanyUserDoc = {
    role: Roles
    active: boolean
}

export default {
    createUser: async (userId: string, companyId: string): Promise<void> => {
        await setDoc(doc(db, COLLECTION, companyId, SUB_COLLECTION, userId), {
            role: Roles.OWNER,
            active: true,
        } as CompanyUserDoc)
    },
}
