import { Snackbar } from '@mui/material'
import React, { useState } from 'react'

interface SnackbarContextProps {
    open: boolean
    message: string
    openSnackbar: (message: string) => void
    closeSnackbar: () => void
}

export const SnackbarContext = React.createContext<
    SnackbarContextProps | undefined
>(undefined)

const SnackbarProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')

    const openSnackbar = (newMessage: string) => {
        setMessage(newMessage)
        setOpen(true)
    }

    const closeSnackbar = () => {
        setOpen(false)
    }

    return (
        <SnackbarContext.Provider
            value={{ open, message, openSnackbar, closeSnackbar }}
        >
            {children}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={closeSnackbar}
                message={message}
            />
        </SnackbarContext.Provider>
    )
}

export default SnackbarProvider
