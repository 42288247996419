import { Close } from '@mui/icons-material'
import {
    Box,
    IconButton,
    Modal,
    ModalProps,
    Paper,
    useTheme,
} from '@mui/material'

/**
 * Modal centered in middle of screen
 */
function ModalCentered(props: ModalProps) {
    const theme = useTheme()

    return (
        <Modal {...props}>
            <Paper
                sx={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    position: 'absolute',
                    p: 4,
                    width: '90%',
                    maxWidth: 500,
                    maxHeight: '90%',
                    overflow: 'auto',
                    [theme.breakpoints.down('sm')]: {
                        width: '95%',
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '8px',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.onClose && props.onClose({}, 'backdropClick')
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
                {props.children}
            </Paper>
        </Modal>
    )
}

export default ModalCentered
