import {
    Timestamp,
    addDoc,
    collection,
    doc,
    getDocs,
    setDoc,
} from 'firebase/firestore'
import {
    CompanyProposalDoc,
    CompanyProposalEditables,
} from './companyProposalService'
import { COLLECTION } from './companyService'
import { db } from '../libs/firebase'
import useSnackbar from '../hooks/UseSnackbar'
import { useUserData } from './userService'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const SUB_COLLECTION = 'projects'

export enum BillingSchedule {
    ONCE = 'once',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export interface CompanyProjectEditables extends CompanyProposalEditables {
    /** Estimated workload in percentage 0-100 */
    estimatedWorkload: number
    billingSchedule: BillingSchedule
}

interface CompanyProjectSysVars {
    createdAt: Timestamp
}

export interface CompanyProjectDoc
    extends CompanyProjectEditables,
        CompanyProjectSysVars {
    /** Not stored in DB but used as reference on fetch */
    id?: string
}

export const useCompanyProjects = () => {
    const user = useUserData()

    return useQuery(
        [SUB_COLLECTION],
        async () => await requests.getProjects(user.data?.companyId || ''),
        {
            enabled: user.data?.companyId != undefined,
            cacheTime: Infinity,
        }
    )
}

export const useCreateCompanyProject = () => {
    const snackbar = useSnackbar()
    const user = useUserData()
    const queryClient = useQueryClient()

    return useMutation(
        (project: CompanyProjectEditables) =>
            requests.createProject(user.data?.companyId || '', project),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SUB_COLLECTION])
                snackbar.openSnackbar('Uppdrag sparades!')
            },
            onError: () => {
                snackbar.openSnackbar(
                    'Det uppstod ett fel. Uppdraget har inte sparats'
                )
            },
        }
    )
}

export const useUpdateCompanyProject = () => {
    const snackbar = useSnackbar()
    const user = useUserData()
    const queryClient = useQueryClient()

    return useMutation(
        (project: CompanyProjectDoc) =>
            requests.updateProject(
                user.data?.companyId || '',
                project.id || '',
                project
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SUB_COLLECTION])
                snackbar.openSnackbar('Uppdraget updaterades!')
            },
            onError: () => {
                snackbar.openSnackbar(
                    'Det uppstod ett fel. Uppdraget har inte uppdaterats'
                )
            },
        }
    )
}

const requests = {
    getProjects: async (companyId: string): Promise<CompanyProjectDoc[]> => {
        const docs = await getDocs(
            collection(db, COLLECTION, companyId, SUB_COLLECTION)
        )
        return docs.docs.map(
            (doc) =>
                ({
                    id: doc.id,
                    ...doc.data(),
                }) as CompanyProjectDoc
        )
    },
    createProject: async (
        companyId: string,
        project: CompanyProjectEditables
    ) => {
        if (companyId.length <= 0) throw Error('Inget företagsid')
        const doc: CompanyProjectDoc = {
            createdAt: Timestamp.fromDate(new Date()),
            ...project,
        }
        return await addDoc(
            collection(db, COLLECTION, companyId, SUB_COLLECTION),
            doc
        )
    },
    updateProject: async (
        companyId: string,
        projectId: string,
        project: CompanyProjectDoc
    ) => {
        if (projectId.length <= 0) throw Error('Inget projectid')
        if (companyId.length <= 0) throw Error('Inget företagsid')
        delete project.id
        return await setDoc(
            doc(db, COLLECTION, companyId, SUB_COLLECTION, projectId),
            project as CompanyProjectDoc
        )
    },
}

export default requests
