import { useState } from 'react'
import useAuth from '../../hooks/UseAuth'
import { Box, Button, Link, TextField, Typography } from '@mui/material'

export default function ResetPassword() {
    const { resetPassword } = useAuth()
    const [email, setEmail] = useState<string>('')

    // Eventhandler for submitting
    const handleSubmit = async (event: any) => {
        event.preventDefault()
        await resetPassword(email)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box sx={{ maxWidth: 400, textAlign: 'center' }}>
                <form onSubmit={handleSubmit}>
                    <Typography sx={{ mb: 4 }} variant="h1">
                        Återställ lösenord
                    </Typography>
                    <TextField
                        type="email"
                        label="E-post"
                        variant="filled"
                        fullWidth
                        margin="normal"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        sx={{ mt: 4 }}
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        Skicka
                    </Button>
                    <Box sx={{ mt: 4 }}>
                        <Link href="/signin">Logga in</Link>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}
